import React from 'react';
// import pdf from '../../../../api/baseUrl';

import './RecentDevelopmentTabs.css';
function Timeline(props) {
  const timelines = props.timelines;
  const icon = props.icon;
  const mystyle = {
    color: '#0a41c0',
    backgroundColor: '#9bb9ff',
  };
  if (Array.isArray(timelines) && timelines.length) {
    return (
      <div className="row time-line-block">
        <ul className="chain timeline">
          {timelines.map((timeline) => {
            return (
              <li>
                <div className="col-md-1 column ui-sortable align-middle icon">
                  <div dangerouslySetInnerHTML={{ __html: icon }} />
                </div>
                <div className="col-md-11 column ui-sortable align-middle text">
                  <p className="timeline-date">{timeline.date}</p>
                  <p>{timeline.recentdevelopment}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else {
    return <div className="row time-line-block">No records</div>;
  }
}

export default Timeline;
