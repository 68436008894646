import React, { useState, useEffect } from 'react';
import { valueCheckGlobal, formatMoney } from '../GlobalFunctions';
import LoadingImage from '../assets/media/loader.gif';
import { baseUrl } from '../api/baseUrl';
import { useCookies } from 'react-cookie';
const StockData = ({ company }) => {
  let compId = company.id;
  // let compId = props.company.id;
  const [stockPrice, setStockPrice] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetch(`${baseUrl}/stockprice/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStockPrice(data);
        setIsLoading(true);
      });
  }, [compId]);

  return (
    <>
      <div className="stock-data-box main-box">
        {/* <div className="title-block">
          <h4>Stock Price Data</h4>
        </div> */}
        {stockPrice ? (
          <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
            <div className="col-lg-6 px-0 pr-xl-1">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th colSpan={2} className="text-left">
                      Stock Data
                    </th>
                    {stockPrice
                      ? stockPrice.map((item, index) => (
                          <th key={index}>{item.label}</th>
                        ))
                      : null}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      Outstanding Shares - Adjusted
                      <span className="table-unit-col">(mn)</span>
                    </td>
                    {stockPrice.map((data, i) => {
                      // return(<td>{valueCheckGlobal(data.adjusted_numbers)}</td>)
                      {
                        /* if(data.label !== "2022"){ */
                      }
                      if (data.adjusted_numbers != undefined) {
                        return (
                          <td key={i}>
                            {valueCheckGlobal(data.adjusted_numbers)}
                          </td>
                        );
                      } else {
                        return <td key={i}>0</td>;
                      }
                      {
                        /* }    */
                      }
                    })}
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Price - High - adj{' '}
                      <span className="table-unit-col">(PKR)</span>
                    </td>
                    {stockPrice.map((data, i) => {
                      // return(<td>PKR {valueCheckGlobal(data.max)}</td>)
                      if (data.max != undefined) {
                        return <td key={i}>{valueCheckGlobal(data.max)}</td>;
                      } else {
                        return <td key={i}>0</td>;
                      }
                    })}
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Price - Low - adj{' '}
                      <span className="table-unit-col">(PKR)</span>
                    </td>
                    {stockPrice.map((data, i) => {
                      // return(<td>PKR {valueCheckGlobal(data.min)}</td>)
                      if (data.min != undefined) {
                        return <td key={i}> {valueCheckGlobal(data.min)}</td>;
                      } else {
                        return <td key={i}>0</td>;
                      }
                    })}
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Price - Average - adj{' '}
                      <span className="table-unit-col">(PKR)</span>{' '}
                    </td>
                    {stockPrice.map((data, i) => {
                      // return(<td>PKR {valueCheckGlobal(data.avg)}</td>)
                      if (data.avg != undefined) {
                        return <td key={i}>{valueCheckGlobal(data.avg)}</td>;
                      } else {
                        return <td key={i}>0</td>;
                      }
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-6 px-0 pl-xl-1">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th colSpan={2}>&nbsp;</th>
                    {stockPrice
                      ? stockPrice.map(
                          (item, index) => <th key={index}>{item.label}</th>
                          // <th key={index}>{item.label}</th>
                        )
                      : null}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      Capital Gain / (Loss){' '}
                      <span className="table-unit-col">(%)</span>
                    </td>
                    {stockPrice.map((data, i) => {
                      if (data.capital_gain != undefined) {
                        return (
                          <td key={i}>{valueCheckGlobal(data.capital_gain)}</td>
                        );
                      } else {
                        return <td key={i}>0</td>;
                      }
                    })}
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Dividend Return{' '}
                      <span className="table-unit-col">(%)</span>
                    </td>
                    {stockPrice.map((data, i) => {
                      if (data.dividend_return != undefined) {
                        return (
                          <td key={i}>
                            {valueCheckGlobal(data.dividend_return)}
                          </td>
                        );
                      } else {
                        return <td key={i}>0</td>;
                      }
                    })}
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Total Return <span className="table-unit-col">(%)</span>
                    </td>
                    {stockPrice.map((data, i) => {
                      if (data.total_return != undefined) {
                        return (
                          <td key={i}>{valueCheckGlobal(data.total_return)}</td>
                        );
                      } else {
                        return <td key={i}>0</td>;
                      }
                    })}
                  </tr>
                  <tr>
                    <td colSpan={7}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default StockData;
