import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Chemical from './chemical';
// import store from '../../store'

import './sectorTabs.css';

import { useEffect } from 'react';

const SectorTabs = () => {
  // console.log("SELECTED COMPANYY INSIDE TABS VIEW ========>", props.selectedCompany)
  const [activeTab, setActiveTab] = useState('chemical');
  function handleRecent(e) {
    if (typeof e === 'object') {
      setActiveTab(e.target.attributes.tabname.value);
    } else {
      setActiveTab(e);
    }
  }

  return (
    <>
      <div className="sector-tabs-view">
        <Tabs id="controlled-tab-example" defaultActiveKey="chemical">
          <Tab eventKey="auto" title="Auto">
            {'auto'}
          </Tab>
          <Tab eventKey="chemical" title="Chemicals">
            <Chemical name={'Auto Sector'} />
            {/* {'chemical'} */}
            {/* <TechnicalIndex name={'Chemical Sector'} /> */}
          </Tab>
          <Tab eventKey="cement" title="Cement">
            {'Cement'}
            {/* <TechnicalIndex name={'Cement Sector'} /> */}
          </Tab>
          <Tab eventKey="e&ps" title="E&Ps">
            {'E&Ps'}
            {/* <TechnicalIndex name={'E&Ps Sector'} /> */}
          </Tab>
          <Tab eventKey="fertilizer" title="Fertilizer">
            {'Fertilizer'}
            {/* <TechnicalIndex name={'Fertilizer Sector'} /> */}
          </Tab>
          <Tab eventKey="oil marketing" title="Oil Marketing">
            {'Oil Marketing'}
            {/* <TechnicalIndex name={'Oil Marketing Sector'} /> */}
          </Tab>
          <Tab eventKey="power" title="Power">
            {'Oil Marketing'}
            {/* <TechnicalIndex name={'Power Sector'} /> */}
          </Tab>
          <Tab eventKey="refineries" title="Refineries">
            {'Oil Marketing'}
            {/* <TechnicalIndex name={'Refineries Sector'} /> */}
          </Tab>
          <Tab eventKey="steel" title="Steel">
            {'Oil Marketing'}
            {/* <TechnicalIndex name={'Steel Sector'} /> */}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default SectorTabs;
