import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

const KSEChart = (props) => {
  let compId = props.company.id;
  const [cookies, setCookies, removeCookies] = useCookies();
  const [destroy, setDestroy] = useState(false);
  const chartRef = useRef();

  useEffect(() => {
    fetch(
      `https://askanalyst-testing.akseerresearch.com/api/kseindex/${compId}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${cookies?.accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data , "ffls response");
        generateChart(data);
      });
  }, [props.company.id]);

  const generateChart = (data1) => {
    if (destroy === true) {
      chartRef.current.dispose();
    }
    //Create targeted element
    let elementPath = 'ffls';
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(elementPath);
    chartRef.current = root;
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    root.numberFormatter.setAll({
      // numberFormat: "#.# ' PKR'",
      numberFormat: '#.#',
      numericFields: ['valueY'],
    });

    setDestroy(true);
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
      })
    );
    // dateAxis.dateFormats.setKey('day', 'MMMM dt');
    chart.root._logo._childrenDisplay.visible = false;
    chart._settings.paddingBottom = 25;
    chart._settings.paddingLeft = 0;

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        // behavior: "none"
        behavior: 'zoomX',
      })
    );
    // cursor.lineY.set("visible", false);

    // Generate random data
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let value = 100;

    function generateData() {
      value = Math.round(Math.random() * 10 - 5 + value);
      am5.time.add(date, 'day', 1);
      return {
        date: date.getTime(),
        value: value,
      };
    }

    function generateDatas(count) {
      let data = [];
      for (var i = 0; i < count; ++i) {
        data.push(generateData());
      }
      return data;
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    // xAxis.get('periodChangeDateFormats')['day'] = 'MMM';

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        gridIntervals: [
          { timeUnit: 'day', count: 1 },
          { timeUnit: 'month', count: 1 },
        ],

        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    xAxis.get('periodChangeDateFormats')['day'] = 'MMM';
    let xRenderer = xAxis.get('renderer');
    xRenderer.grid.template.setAll({
      stroke: 0,
    });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
      })
    );
    let yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.setAll({
      min: yAxis.getPrivate('min'),
      max: yAxis.getPrivate('max'),
      start: 0,
      end: 1,
      stroke: 0,
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: props.company.symbol,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'adjusted_price',
        valueXField: 'date',
        fill: am5.color('#0B254A'),
        stroke: am5.color('#0B254A'),
        tooltip: am5.Tooltip.new(root, {
          labelText: `Adjusted Price : {valueY}`,
        }),
      })
    );

    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'KSE-100',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'kse_100',
        valueXField: 'date',
        fill: am5.color('#3969D5'),
        stroke: am5.color('#3969D5'),
        tooltip: am5.Tooltip.new(root, {
          labelText: 'KSE-100 : {valueY}',
        }),
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series2.strokes.template.setAll({
      strokeWidth: 3,
    });

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
        height: 10,
        // background: "#ececec"
      })
    );

    let scrollbarX = chart.get('scrollbarX');
    scrollbarX.thumb.setAll({
      fill: am5.color(0xf5f5f5),
      fillOpacity: 0.1,
    });

    var legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'categoryX',
        x: am5.percent(35),
        y: am5.percent(100),
        centerX: am5.percent(0),
        centerY: am5.percent(0),
      })
    );
    legend.data.setAll(chart.series.values);

    var nowDate = new Date();
    var current_date =
      nowDate.getFullYear() +
      '-' +
      (nowDate.getMonth() + 1) +
      '-' +
      nowDate.getDate();
    var current_time =
      nowDate.getHours() +
      ':' +
      nowDate.getMinutes() +
      ':' +
      nowDate.getSeconds();
    var date_time = current_date + ' ' + current_time;

    var exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data1,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
      filePrefix: 'KSE-100-' + date_time,
    });

    /*exporting.events.on('dataprocessed', function (ev) {
      for (var i = 0; i < ev.data.length; i++) {
        ev.data[i].sum = ev.data[i].value + ev.data[i].value2;
      }
    });*/

    exporting.get('menu').set('items', [
      {
        type: 'format',
        format: 'jpg',
        label: 'JPEG',
        iconTag: 'image.jpg',
      },
      {
        type: 'format',
        format: 'xlsx',
        label: 'Excel',
      },
      {
        type: 'format',
        format: 'pdf',
        label: 'PDF',
      },
    ]);

    // Set data
    // let data = generateDatas(1200);
    // let data1 = generateDatas(2400);

    // console.log(data , "fflfs data example");
    series.data.setAll(data1);
    series2.data.setAll(data1);

    // series.data.setAll(data);
    // series2.data.setAll(data1);
    // console.log("Data from KSE100 Index Chart,", data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);
    // chart.appear(1100, 100);
  };

  return (
    <>
      <div id="ffls" className="ffls-chart"></div>
    </>
  );
};

export default KSEChart;
