import React, { useEffect, useState } from 'react';
import { baseUrl, icon } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';

function TimeLine(props) {
  let compId = props.compnay_id;
  let symbol = props.compnay_symbol.toLowerCase();
  // console.log(symbol, compId);
  const [isLoading, setIsLoading] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();

  useEffect(() => {
    fetch(`${baseUrl}/timelines/${symbol}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTimelineData(data);
        // console.log('ab change howa hai', data);
        setIsLoading(true);
      });
  }, [compId]);

  if (isLoading) {
    return (
      <div className="time-line-block col-12 p-0">
        <div className="title-block block-head-bg-dark">
          <h4>Timeline</h4>
        </div>
        <div className="col-12 data-block d-flex flex-wrap justify-content-between p-0">
          <ul className="chain timeline">
            {timelineData.map((value, key) => {
              return (
                <li>
                  <div className="col-md-1 column ui-sortable align-middle icon">
                    <div dangerouslySetInnerHTML={{ __html: icon }} />
                  </div>

                  <div className="col-md-11 column ui-sortable align-middle text">
                    <p className="timeline-date">2005-August</p>
                    <p>
                      Company completed its 50 years of timeline 50 years of
                      {value.timeline}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="time-line-block col-12 p-0">
        <div className="title-block block-head-bg-dark">
          <h4>Timeline</h4>
        </div>
      </div>
    );
  }
}

export default TimeLine;
