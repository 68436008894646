import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import SectorCharts from '../charts/sectorCharts';
import './chemical.css';
import { Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import LoadingImage from '../../../../assets/media/loader.gif';

const Chemical = (props) => {
  const [pvcReport, setPvcReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies();

  const accessToken = cookies.accessToken;
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${cookies?.accessToken}`;
  useEffect(() => {
    axios
      .post('https://askanalyst-testing.akseerresearch.com/api/pvcreport')
      .then(function (response) {
        setPvcReport(response.data);
        console.log('pvcreport', pvcReport);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log('pvcreport', error);
      });
  }, []);
  // console.log(props.company , "company");
  if (!accessToken) {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  } else {
    if (isLoading) {
      return (
        <>
          <div className="ratio-main">
            <table className="table table-responsive  sector-table">
              <thead>
                <tr>
                  <th colSpan={2} className="text-left">
                    {/* {props.name} */}
                    {'Monthly & Quarterly Data'}
                  </th>
                  <th>&nbsp;</th>
                  <th>{pvcReport.header.Q1}</th>
                  <th>{pvcReport.header.Q2}</th>
                  <th>{pvcReport.header.YoY}</th>
                  <th>{pvcReport.header.Q4}</th>
                  <th>{pvcReport.header.QoQ}</th>
                  <th>{pvcReport.header.currentMonthCurrentYear}</th>
                  <th>{pvcReport.header.CurrentMonthpreviousYear}</th>
                  <th>{pvcReport.header.YoY}</th>
                  <th>{pvcReport.header.previousMonthCurrentYear}</th>
                  <th>{pvcReport.header.MoM}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Prices</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.price.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td>{value.unit}</td>
                        {value.price.map((p, key) => {
                          return (
                            <>
                              <td>{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Margin</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.margin.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td>{value.unit}</td>
                        {value.margin.map((p, key) => {
                          return (
                            <>
                              <td>{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* debugger; */}
          <SectorCharts />
          <div className="ratio-main mb">
            <table className="table table-responsive sector-table">
              <thead>
                <tr>
                  <th colSpan={2} className="text-left">
                    {'Annual Data'}
                  </th>
                  <th>&nbsp;</th>
                  {pvcReport.header2.map((value, key) => {
                    return (
                      <>
                        <th>{value.value}</th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Prices</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.tenyeardata.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td>{value.unit}</td>
                        {value.data.map((p, key) => {
                          return (
                            <>
                              <td>{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Margin</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.tenyearmargin.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td>{value.unit}</td>
                        {value.data.map((p, key) => {
                          return (
                            <>
                              <td>{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-left YoY-color">
                          {'YoY'}
                        </td>
                        <td>&nbsp;</td>
                        {value.YoY.map((y, key) => {
                          return (
                            <>
                              <td className="YoY-color">{y.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      );
    } else {
      return (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      );
    }
  }
};

export default Chemical;
