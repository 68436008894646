import React from 'react';
import Slider from 'react-slick';
import SlickNextArrow from '../../../common/slicknext';
import SlickPrevArrow from '../../../common/slickprev';
import Chart from './Chart';

export default function ChartSection({ section, company }) {
  const settings = {
    dots: false,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="finance-income col-12 p-0">
      <div className="title-block block-head-bg-dark">
        <h4>Charts - {section.section}</h4>
      </div>
      <div className="col-12 data-block p-0">
        <Slider {...settings}>
          {section.graphs.map((graph, index) => {
            return (
              <>
                <Chart
                  section={`financial-chart`}
                  data={graph}
                  index={index}
                  company={company}
                />
              </>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
