import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import './industryAvg.css';
import loader from '../../../assets/media/_loader.gif';

const IndustryAvg = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //     setData(props.industryData)
  // } , [props.industryData])

  useEffect(() => {
    axios({
      method: 'post',
      url: 'https://askanalyst-testing.akseerresearch.com/api/industryaverage',
      data: { company_id: props.company.id ? props.company.id : 189 },
    })
      .then(function (response) {
        setData(response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });

    /* return () => {
      setData(undefined);
    }; */
  }, [props.company.id]);

  return (
    <div
      className="industry-avg industry-average-table"
      style={styles.position}
    >
      {isLoading && data ? (
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={2} className="text-left">
                Industry Averages
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={2}>-</td>
            </tr>
            <tr>
              <td colSpan={2}>-</td>
            </tr>
            <tr>
              <td colSpan={2}>-</td>
            </tr>
            <tr>
              <td colSpan={2}>-</td>
            </tr>
            {data.map((d) => {
              return (
                <tr>
                  <td>
                    {d.label} <span className="table-unit-col">({d.unit})</span>
                  </td>
                  <td>{valueCheckGlobal(d.value)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        // WHEN DATA IS BEING LOADED ......

        <>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={2} className="text-left">
                  Industry Averages
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={styles.noContent} className="no-content-load text-center">
            <img style={styles.noContentImage} src={loader} alt="" />
          </div>
        </>
      )}
    </div>
  );
};

export default IndustryAvg;

const styles = {
  noContent: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    transform: 'translateX(calc(50% - 90px))',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '999',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
