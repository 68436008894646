import React, { useState, useEffect } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
// import axios from 'axios';
import { useCookies } from 'react-cookie';
// import LoadingImage from '../../../assets/media/loader.gif';
import LoadingImage from '../../../assets/media/loader.gif';
import { baseUrl } from '../../../api/baseUrl';
import loader from '../../../assets/media/_loader.gif';

const CompFinancials = ({ company }) => {
  let compId = company.id;
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [companyFinancials, setCompanyFinancials] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/companyfinancial/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCompanyFinancials(data);
        setIsLoading(true);
      });
  }, [compId]);
  let withPercent = {
    DIVIDEND_YIELD: 'DIVIDEND YIELD',
    GROSS_MARGIN: 'GROSS MARGIN',
    EBIDTA_MARGIN: 'EBITDA MARGIN',
    OPERATING_MARGIN: 'OPERATING MARGIN',
    NET_MARGIN: 'NET MARGIN',
    ROE: 'ROE',
  };
  if (companyFinancials.length && isLoading) {
    return (
      <div className="comp-financials">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={2} className="text-left">
                Company Financials
              </th>
              {companyFinancials[0].data.slice(-6).map((item, index) => {
                return index != 5 ? (
                  <th key={'year' + index}>{item.year}</th>
                ) : null;
              })}
              {/* <th>TTM</th> */}
            </tr>
          </thead>
          {companyFinancials ? (
            <tbody>
              {companyFinancials.map((d, index) => {
                return (
                  <tr key={index}>
                    <td colSpan={2}>
                      {d.label}{' '}
                      <span className="table-unit-col">({d.unit})</span>
                    </td>
                    {d.data
                      .slice(-6)
                      .map((v, i) =>
                        i != 5 ? (
                          <td key={i}>
                            {v.value ? valueCheckGlobal(v.value) : '0'}
                          </td>
                        ) : null
                      )}
                    {/* <td>N/A</td> */}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            ''
          )}
        </table>
      </div>
    );
  } else {
    var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    return (
      <div className="comp-financials">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={7} className="text-left">
                Company Financials
              </th>
            </tr>
          </thead>
          <tbody>
            {arr.map((v, i) => {
              return (
                <tr>
                  <td colSpan={7}>
                    <span style={{ color: 'white' }}>.</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={styles.noContent} className="no-content-load text-center">
          <img style={styles.noContentImage} src={loader} alt="" />
        </div>
      </div>
    );
  }
};

export default CompFinancials;
const styles = {
  noContent: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    transform: 'translateX(calc(50% - 90px))',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    // justifyContent: 'center',
    zIndex: '999',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
