import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import OverviewIndex from './overview';
import ResearchIndex from './research';
import ValuationIndex from './valuation';
import TechnicalIndex from './technical';
// import store from '../../store'
import IncomeTable from './income';
import BalanceTable from './balance';
import CashFlowTable from './cashflow';
import RatioTable from './ratio';
import RecentDevelop from './recentdevelope/index';
import './tabs.css';

import { useEffect } from 'react';

const TabsView = (props) => {
  // console.log('SELECTED COMPANYY INSIDE TABS VIEW ========>', props);
  const [activeTab, setActiveTab] = useState('research');
  function handleRecent(e) {
    if (typeof e === 'object') {
      setActiveTab(e.target.attributes.tabname.value);
    } else {
      setActiveTab(e);
    }
  }

  const showTab = (sect_id) => {
    if (sect_id === 13) {
      return false;
    }
    if (sect_id === 3) {
      return false;
    }
    if (sect_id === 15) {
      return false;
    }
    return true;
  };

  let researchTab = false;
  if (window.location.host === 'analyst.alphacapital.com.pk') {
    researchTab = false;
  } else if (window.location.host === 'askanalyst.akseerresearch.com') {
    researchTab = false;
  } else {
    researchTab = true;
  }

  // useEffect(() => {
  // });
  // console.log('tabsView', props.company);

  return (
    <>
      <div className="main-tabs-view">
        <Tabs id="controlled-tab-example">
          <Tab eventKey="overview" title="Overview">
            <OverviewIndex
              industryData={props.industryData ? props.industryData : []}
              handleRecent={handleRecent}
              company={props.company}
              // allData={props.allData.overview}
            />
          </Tab>
          {researchTab ? (
            <Tab eventKey="research" title="Research">
              <ResearchIndex company={props.allData['company'][0]} />
            </Tab>
          ) : (
            ''
          )}

          <Tab eventKey="technical" title="Technical">
            <TechnicalIndex company={props.company} />
          </Tab>
          <Tab eventKey="valuation" title="Valuation">
            <ValuationIndex
              company={props.company}
              // allData={props.allData.valuation}
            />
          </Tab>
          <Tab eventKey="income-statement" title="Income Statement">
            <IncomeTable
              company={props.company}
              company_id={props.company.id}
              // allData={props.allData.income_statement}
            />
          </Tab>
          <Tab eventKey="balance-sheet" title="Balance Sheet">
            <BalanceTable
              company={props.company}
              company_id={props.company.id}
              // allData={props.allData.balance_sheet}
            />
          </Tab>
          {showTab(props.allData.company[0].sector_id) && (
            <Tab eventKey="cash-flow" title="Cash Flow">
              <CashFlowTable company={props.company} />
            </Tab>
          )}
          <Tab eventKey="ratio" title="Ratios">
            <RatioTable company={props.company} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default TabsView;
