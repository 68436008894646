import React from 'react';

function CompanyInformation(props) {
  if (props.introduction) {
    // console.log('intro inside');
    let into = JSON.parse(props.introduction);
    return (
      <div className="comp-introduction-block">
        <div className="title-block block-head-bg-dark">
          <h4>{into.heading}</h4>
        </div>
        <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
          <div dangerouslySetInnerHTML={{ __html: into.description }} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="comp-introduction-block">
        <div className="title-block block-head-bg-dark">
          <h4>{'Company Introduction'}</h4>
        </div>
      </div>
    );
  }
}

export default CompanyInformation;
