import { ActionTypes } from "../constants/ActionTypes";


export const setAllData = (data) => {
    return {
        type: ActionTypes.SET_ALL_DATA,
        payload: data
    }
}

export const getAllData = (data) => {
    return {
        type: ActionTypes.GET_ALL_DATA,
        payload: data
    }
}

export const getSelectedData = (data) => {
    return {
        type: ActionTypes.GET_SELECTED_DATA,
        payload: data
    }
}

export const setSelectedData = (data) => {
    return {
        type: ActionTypes.SET_SELECTED_DATA,
        payload: data
    }
}

export const getData = (data) => {
    return {
        type: ActionTypes.GET_DATA,
        payload: data
    }
}

export const setData = (data) => {
    return {
        type: ActionTypes.SET_DATA,
        payload: data
    }
}