import React from 'react';
import './research.css';
import CompanyInformation from './CompanyInformation';
import RevenueDriver from './RevenueDriver';
import CostDriver from './CostDriver';
import Heading4 from './Heading4';
import Heading5 from './Heading5';
import PublishReport from './PublishReport';
import TimeLine from './TimeLine';
import RecentDevelopment from './RecentDevelopment';
import Thumbnail from './Rectangle.png';
import { Rectangle } from '@amcharts/amcharts5';
import { Tabs, Tab } from 'react-bootstrap';

function reseach(props) {
  // console.log('ok men', props.company);

  return (
    <div className="research-main">
      <div className="d-flex flex-wrap justify-content-between">
        <div className="col-xl-7 d-flex flex-wrap justify-content-between p-0">
          <div className="col-xl-12 p-0 pr-lg-2">
            <CompanyInformation
              compnay_id={props.company.id}
              introduction={props.company.company_introduction}
            />
            <RevenueDriver revenue={props.company.revenue_drivers} />
            <CostDriver cost={props.company.cost_drivers} />
            <Heading4 />
            <Heading5 />
          </div>
        </div>
        <div className="col-xl-5 flex-wrap justify-content-between px-0 pl-xl-2">
          <PublishReport
            compnay_id={props.company.id}
            compnay_symbol={props.company.symbol}
          />
          <TimeLine
            compnay_id={props.company.id}
            compnay_symbol={props.company.symbol}
          />
          <RecentDevelopment compnay_id={props.company.id} />
        </div>
      </div>
    </div>
  );
}

export default reseach;
