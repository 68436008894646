import React, { useEffect, useState } from 'react';
import { getState } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import * as actions from './actions';
import Login from './layout/login/login';
import Signup from './components/Signup/signup';
import Alphaanalyst from './components/alphaanalyst';
import Sector from './components/Sector';
import Reset from './components/ResetPassword/reset';
import Welcome from './layout/flowscreen/welcome';
import Expire from './layout/flowscreen/expire';
import ResetExpire from './layout/flowscreen/resetexpire';
import Dashboard from './dashboard/dashboard';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute';

function App(props) {
  return (
    <>
      <ToastContainer
        draggable={false}
        transition={Bounce}
        autoClose={5000}
        position="bottom-right"
      />
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/welcome/:name?" component={Welcome} />
          <Route path="/expire" exact component={Expire} />
          <Route path="/reset_expire" exact component={ResetExpire} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/reset_password/:token?" component={Reset} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/financials" exact component={Alphaanalyst} />
          <Route path="/sector" exact component={Sector} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
