import React from 'react';
import TechnicalChart from './technical.jsx';
import './technical.css';

const Technical = (props) => {
  // console.log(props.company , "company");
  return (
    <div className="overview-main">
      <div className="d-flex flex-wrap justify-content-between">
        <div className="col-xl-12 d-flex p-0">
          <div className="technical-block col-lg-12 p-0">
            <div className="title-block block-head-bg-dark">
              <h4>Technical Chart</h4>
            </div>
            <div className="col-12 data-block d-flex flex-wrap justify-content-between">
              <TechnicalChart company={props.company} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technical;
