export const baseUrl = 'https://askanalyst-testing.akseerresearch.com/api';

export const icon = `
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#FEE9D7" />
    <path d="M12.9999 9.6665C12.5579 9.6665 12.134 9.8421 11.8214 10.1547C11.5088 10.4672 11.3333 10.8911 11.3333 11.3332V24.6665C11.3333 25.1085 11.5088 25.5325 11.8214 25.845C12.134 26.1576 12.5579 26.3332 12.9999 26.3332H22.9999C23.4419 26.3332 23.8659 26.1576 24.1784 25.845C24.491 25.5325 24.6666 25.1085 24.6666 24.6665V14.6665L19.6666 9.6665H12.9999ZM12.9999 11.3332H18.8333V15.4998H22.9999V24.6665H12.9999V11.3332ZM14.6666 17.9998V19.6665H21.3333V17.9998H14.6666ZM14.6666 21.3332V22.9998H18.8333V21.3332H14.6666Z" fill="#AC896B" />
</svg>`;
export const pdf = `
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5687 1.42432L20.3939 5.41182V20.5753H6.10425V20.6248H20.4427V5.462L16.5687 1.42432Z" fill="#909090" />
    <path d="M16.5215 1.375H6.05566V20.5755H20.3942V5.41269L16.5215 1.375Z" fill="#F4F4F4"/>
    <path d="M5.9505 2.40625H1.55737V7.09981H15.3761V2.40625H5.9505Z" fill="#7A7B7C" />
    <path d="M15.4494 7.02024H1.64648V2.32324H15.4494V7.02024Z" fill="#DD2025" />
    <path d="M6.22327 3.11734H5.32471V6.41734H6.03146V5.30428L6.18752 5.31322C6.33916 5.31061 6.48938 5.28344 6.63233 5.23278C6.75767 5.18967 6.87296 5.12162 6.97127 5.03272C7.0713 4.94803 7.15017 4.84115 7.20158 4.72059C7.27053 4.52019 7.29516 4.3072 7.27377 4.09634C7.26948 3.94572 7.24307 3.79654 7.19539 3.65359C7.152 3.5504 7.08759 3.45738 7.00627 3.38044C6.92496 3.30351 6.82851 3.24434 6.72308 3.20672C6.63193 3.17372 6.53774 3.14977 6.44189 3.13522C6.36931 3.12402 6.29602 3.11805 6.22258 3.11734M6.09264 4.69447H6.03146V3.67697H6.16414C6.22271 3.67275 6.28147 3.68174 6.33609 3.70327C6.39071 3.72481 6.43979 3.75835 6.47971 3.80141C6.56243 3.9121 6.60662 4.04685 6.60552 4.18503C6.60552 4.35416 6.60552 4.50747 6.45289 4.61541C6.34294 4.67588 6.21779 4.7038 6.09264 4.69447ZM8.61646 3.10841C8.54014 3.10841 8.46589 3.11391 8.41364 3.11597L8.25002 3.12009H7.71377V6.42009H8.34489C8.58609 6.4267 8.82623 6.38583 9.05164 6.29978C9.23308 6.22781 9.39375 6.1118 9.51914 5.96222C9.64108 5.81128 9.7286 5.63554 9.77558 5.44728C9.82959 5.23407 9.85594 5.01478 9.85396 4.79484C9.86729 4.53508 9.84719 4.27467 9.79414 4.02003C9.74381 3.83259 9.64954 3.65984 9.51914 3.51609C9.41684 3.40002 9.29161 3.30639 9.15133 3.24109C9.03087 3.18535 8.90415 3.14426 8.77389 3.11872C8.7221 3.11016 8.66964 3.10625 8.61714 3.10703M8.49202 5.81372H8.42327V3.70722H8.43221C8.57394 3.69091 8.71735 3.71648 8.84471 3.78078C8.93798 3.85526 9.01397 3.94908 9.06746 4.05578C9.12518 4.16807 9.15845 4.29132 9.16508 4.41741C9.17127 4.56866 9.16508 4.69241 9.16508 4.79484C9.16788 4.91284 9.16029 5.03086 9.14239 5.14753C9.1212 5.26731 9.08203 5.38321 9.02621 5.49128C8.96302 5.59176 8.87765 5.67643 8.77664 5.73878C8.69182 5.79364 8.59138 5.81924 8.49064 5.81166M11.9831 3.12009H10.3125V6.42009H11.0193V5.11109H11.913V4.49784H11.0193V3.73334H11.9818V3.12009"fill="#464648" />
    <path d="M14.9745 13.9253C14.9745 13.9253 17.1662 13.5279 17.1662 14.2766C17.1662 15.0253 15.8084 14.7207 14.9745 13.9253ZM13.354 13.9824C13.0058 14.0593 12.6664 14.172 12.3413 14.3185L12.6163 13.6998C12.8913 13.081 13.1767 12.2375 13.1767 12.2375C13.5048 12.7898 13.8866 13.3084 14.3165 13.7857C13.9923 13.8341 13.671 13.9002 13.354 13.9837V13.9824ZM12.4864 9.5136C12.4864 8.86116 12.6975 8.6831 12.8618 8.6831C13.0261 8.6831 13.211 8.76216 13.2172 9.32866C13.1637 9.8983 13.0444 10.4598 12.8618 11.002C12.6116 10.5468 12.4821 10.0351 12.4857 9.51566L12.4864 9.5136ZM9.29022 16.7434C8.61784 16.3412 10.7003 15.103 11.0777 15.0631C11.0757 15.0638 9.99422 17.1641 9.29022 16.7434ZM17.8063 14.3653C17.7994 14.2965 17.7375 13.5355 16.3832 13.5678C15.8186 13.5587 15.2544 13.5985 14.6967 13.6867C14.1565 13.1425 13.6913 12.5287 13.3135 11.8614C13.5515 11.1735 13.6956 10.4566 13.7418 9.73016C13.7218 8.90516 13.5245 8.43216 12.892 8.43904C12.2595 8.44591 12.1674 8.99935 12.2506 9.82298C12.3321 10.3764 12.4858 10.9168 12.7078 11.4304C12.7078 11.4304 12.4156 12.3399 12.0292 13.2447C11.6428 14.1494 11.3788 14.6238 11.3788 14.6238C10.707 14.8425 10.0745 15.1675 9.50541 15.5863C8.93891 16.1136 8.70859 16.5185 9.00697 16.9235C9.26409 17.2727 10.164 17.3518 10.9684 16.2979C11.3958 15.7535 11.7863 15.1811 12.1372 14.5846C12.1372 14.5846 13.3637 14.2484 13.7452 14.1563C14.1268 14.0642 14.5881 13.9913 14.5881 13.9913C14.5881 13.9913 15.708 15.1181 16.7881 15.0782C17.8682 15.0384 17.8159 14.4327 17.809 14.3667" fill="#DD2025" />
    <path d="M16.4685 1.42822V5.46591H20.3412L16.4685 1.42822Z" fill="#909090" />
    <path d="M16.5212 1.375V5.41269H20.3939L16.5212 1.375Z" fill="#F4F4F4" />
    <path d="M6.17029 3.06412H5.27173V6.36412H5.98123V5.25175L6.13798 5.26068C6.28962 5.25807 6.43984 5.23091 6.58279 5.18025C6.70812 5.13712 6.82341 5.06907 6.92173 4.98018C7.02101 4.89527 7.09915 4.78841 7.14998 4.66806C7.21893 4.46765 7.24356 4.25466 7.22217 4.04381C7.21787 3.89318 7.19147 3.74401 7.14379 3.60106C7.10039 3.49787 7.03598 3.40485 6.95467 3.32791C6.87335 3.25097 6.77691 3.19181 6.67148 3.15418C6.5799 3.12086 6.48525 3.09668 6.38892 3.082C6.31633 3.0708 6.24304 3.06482 6.1696 3.06412M6.03967 4.64125H5.97848V3.62375H6.11185C6.17041 3.61952 6.22918 3.62851 6.2838 3.65005C6.33842 3.67159 6.3875 3.70513 6.42742 3.74818C6.51013 3.85888 6.55432 3.99362 6.55323 4.13181C6.55323 4.30093 6.55323 4.45425 6.4006 4.56218C6.29065 4.62266 6.16549 4.64989 6.04035 4.64056M8.56348 3.05518C8.48717 3.05518 8.41292 3.06068 8.36067 3.06275L8.1991 3.06687H7.66285V6.36687H8.29398C8.53517 6.37348 8.77531 6.3326 9.00073 6.24656C9.18217 6.17459 9.34283 6.05858 9.46823 5.909C9.59017 5.75806 9.67768 5.58232 9.72467 5.39406C9.77868 5.18084 9.80502 4.96156 9.80304 4.74162C9.81638 4.48185 9.79627 4.22145 9.74323 3.96681C9.69289 3.77937 9.59862 3.60662 9.46823 3.46287C9.36592 3.3468 9.24069 3.25316 9.10042 3.18787C8.97995 3.13213 8.85323 3.09104 8.72298 3.0655C8.67118 3.05694 8.61872 3.05303 8.56623 3.05381M8.4411 5.7605H8.37235V3.654H8.38129C8.52302 3.63769 8.66643 3.66326 8.79379 3.72756C8.88706 3.80203 8.96306 3.89586 9.01654 4.00256C9.07426 4.11485 9.10753 4.2381 9.11417 4.36418C9.12035 4.51543 9.11417 4.63918 9.11417 4.74162C9.11697 4.85962 9.10937 4.97764 9.09148 5.09431C9.07029 5.21409 9.03112 5.32998 8.97529 5.43806C8.9121 5.53854 8.82673 5.62321 8.72573 5.68556C8.64091 5.74042 8.54046 5.76601 8.43973 5.75843M11.9302 3.06687H10.2595V6.36687H10.9663V5.05787H11.86V4.44462H10.9663V3.68012H11.9288V3.06687" fill="white" />
</svg>`;
