import React, { useEffect, useState, useRef } from 'react';
// import moment from 'moment';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

// import * from '@amcharts/amcharts5/plugins/radar';
import Pdfsvg from '../../../assets/media/files/Pdf.svg';
const ValuationChart = (props) => {
  const id = props.eleId.id;
  const [destroy, setDestroy] = useState(false);
  const chartRef = useRef();
  // console.log(props.eleId.data , "valuation");

  const chartName = props.eleId.title;
  const color = props.eleId.color;

  useEffect(() => {
    if (props.eleId.data !== undefined) {
      generateChart(id, props.eleId.data);
    } else {
      return null;
    }
    // generateChart(id , props.eleId.data);
    // generateChartCheck(id , props.eleId.data)
  });

  const generateChart = (ele, data1) => {
    if (destroy === true) {
      chartRef.current.dispose();
    }
    let elementPath = ele + '-valuation';
    let root = am5.Root.new(elementPath);
    chartRef.current = root;
    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX'],
    });
    root.setThemes([am5themes_Animated.new(root)]);

    const myTheme = am5.Theme.new(root);

    myTheme.rule('Label').setAll({
      fill: am5.color('#000000'),
      // fontSize: '12px',
    });

    root.numberFormatter.setAll({
      numberFormat: '#.#',
      numericFields: ['valueY'],
    });

    setDestroy(true);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        connect: false,
      })
    );
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      })
    );
    cursor.lineY.set('visible', false);

    chart.root._logo._childrenDisplay.visible = false;
    chart._settings.paddingBottom = 25;
    // chart._settings.paddingLeft = 5;

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
      })
    );

    let yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });
    let xRenderer = xAxis.get('renderer');
    xRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    // Add series
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: chartName,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText:
            chartName.replace(' Multiple', '') + ': ' + '{valueY}' + 'x',
        }),
      })
    );

    series.set('fill', am5.color('#' + color));
    series.set('stroke', am5.color('#' + color));

    series.fills.template.setAll({ fillOpacity: 0.3, visible: true });
    let series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Mean',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'mean',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '5 years average: ' + '{valueY}',
        }),
      })
    );

    series1.set('fill', am5.color('#' + color));
    series1.set('stroke', am5.color('#' + color));

    let scrollbar = chart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 10,
        // wheelable: 10,
        // end: 1,
        // start: 0,
      })
    );

    var exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data1,
    });

    exporting.events.on('dataprocessed', function (ev) {
      for (var i = 0; i < ev.data.length; i++) {
        ev.data[i].sum = ev.data[i].value + ev.data[i].value2;
      }
    });

    exporting.get('menu').set('items', [
      {
        type: 'format',
        format: 'jpg',
        label: 'JPEG',
        iconTag: 'image.jpg',
      },
      {
        type: 'format',
        format: 'xlsx',
        label: 'Excel',
      },
      {
        type: 'format',
        format: 'pdf',
        label: 'PDF',
      },
    ]);

    // zunair.nusrat@gmail.com
    // Og36^5

    // console.log('export-' + id);

    // chart.exporting.menu.items[0].icon = Pdfsvg;

    series.strokes.template.setAll({
      strokeWidth: 2,
    });

    series1.strokes.template.setAll({
      strokeWidth: 2,
    });

    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['date'],
    });

    var legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'categoryX',
        x: am5.percent(30),
        y: am5.percent(100),
        centerX: am5.percent(0),
        centerY: am5.percent(0),
      })
    );
    legend.data.setAll(chart.series.values);

    series.data.setAll(data1);
    series1.data.setAll(data1);
    // series2.data.setAll(data1);
    // series3.data.setAll(data1);
    // series4.data.setAll(data1);
    // series5.data.setAll(data1);
    // sbSeries.data.setAll(data1);
    series.appear(1000);
    series1.appear(1000);
    chart.appear(1000, 100);
  };

  // const generateChartCheck = (ele, data1) => {
  //   if( props.eleId.data !== undefined ) {
  //     generateChart(ele, data1)
  //   } else {
  //     return null;
  //   }
  // }

  if (props.eleId.data !== undefined) {
    return (
      <div id={props.eleId.id + '-valuation'} className="valuation-chart"></div>
    );
  } else {
    return (
      <>
        <div className="d-flex w-100 h-100 justify-content-center align-items-center h-100">
          <h1 className="text-primary">0</h1>
        </div>
      </>
    );
  }
  // () ? props.eleId.data : "CAUSING ERROR!"

  // return (
  //   <>
  //     {/* <div id={props.eleId.id + "-valuation"} className='valuation-chart'>
  //     </div> */}
  //     <h1>Testing Valuation Charts</h1>
  //   </>
  // )
};

export default ValuationChart;
