import React, { useState, useEffect } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import './balance.css';
import { useCookies } from 'react-cookie';
// import LoadingImage from '../../../assets/media/loader.gif';
import LoadingImage from '../../../assets/media/loader.gif';
import { baseUrl } from '../../../api/baseUrl';

const BalanceTable = (props) => {
  let compId = props.company.id;
  // let compId = 200;
  const [valData, setValData] = useState([]);
  const [years, setYears] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);

  function formatMoney(n) {
    return (Math.round(n * 100) / 100).toLocaleString();
  }

  /*  useEffect(() => {
    setValData(props.allData);
  }); */
  // console.log(valData);

  // useEffect(() => {
  //   setValData(props.allData);
  //   // setYears(props.allData.current_asset[0].data.slice(-10));
  // }, [props.allData]);

  useEffect(() => {
    fetch(`${baseUrl}/balancesheet/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValData(data);
        setIsLoading(true);
      });
  }, [props.company.id]);

  let totalHeadings = ['Total Asset', 'Total Liabilities', 'Total Equity'];

  let totalSubHeadings = [
    'Total current assets',
    'Total non-current assets',
    'Total current liabilities',
    'Total non-current liabilities',
    'Total equity',
  ];

  const headingCheck = (heading) => {
    return totalHeadings.includes(heading);
  };

  const subheadingCheck = (heading) => {
    return totalSubHeadings.includes(heading);
  };

  if (isLoading) {
    return (
      <div className="balance-main">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={2}>Balance Sheet - PKR (mn)</th>
              {valData[0].data[0].data
                .slice(-11)
                .map((y, i) =>
                  i != valData[0].data[0].data.slice(-11).length ? (
                    <th key={i}>{y.year}</th>
                  ) : null
                )}
            </tr>
          </thead>
          <tbody>
            {valData.map((main_heading, i) => {
              if (!headingCheck(main_heading.label)) {
                return (
                  <React.Fragment key={i}>
                    <tr className="bg-td bold">
                      <td colSpan={13}>{main_heading.label}</td>
                    </tr>
                    {main_heading.data.map((sub_heading, i) => {
                      let bold;
                      let boldRow;
                      if (!subheadingCheck(sub_heading.label)) {
                        bold = '0';
                        boldRow = '';
                      } else {
                        boldRow = 'bold';
                        bold = '800';
                      }
                      return (
                        <tr
                          key={i}
                          className={boldRow}
                          style={{ fontWeight: bold }}
                        >
                          <td
                            colSpan={2}
                            className={boldRow}
                            style={{ fontWeight: bold }}
                          >
                            {/* {' '} */}
                            {sub_heading.label}
                            {/* {bold} */}
                            {/* <span className='table-unit-col'>({sub_heading.unit})</span> */}
                          </td>
                          {sub_heading.data
                            .slice(-11)
                            .map((d, i) =>
                              i != sub_heading.data.slice(-11).length ? (
                                <td key={i}>{valueCheckGlobal(d.value)}</td>
                              ) : null
                            )}
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={i}>
                  <tr>
                    <td style={{ fontWeight: '800' }} colSpan={2}>
                      {main_heading.data.map((total) => total.label)}
                    </td>
                    {main_heading.data.map((total_data) =>
                      total_data.data.slice(-11).map((_total_data, i) =>
                        i != 10 ? (
                          <td key={i} style={{ fontWeight: '800' }}>
                            {valueCheckGlobal(_total_data.value)}
                          </td>
                        ) : null
                      )
                    )}
                  </tr>
                  {i !== valData.length - 1 ? (
                    <tr className="empty-row" style={styles.emptyRow}>
                      <td colSpan={12}></td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div
        style={{ width: '100%', height: '100%', color: '#426fd7' }}
        className="main-loader d-flex flex-column justify-content-center align-items-center"
      >
        <img src={LoadingImage} style={{ height: '500px' }} />
      </div>
    );
  }
};

export default BalanceTable;

const styles = {
  emptyRow: {
    width: '0.1px',
    border: 'none !important',
  },
};
