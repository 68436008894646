import React, { useEffect, useState } from 'react';
import Thumbnail from './abot.svg';
import { baseUrl, pdf } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

function PublishReport(props) {
  let compId = props.compnay_id;
  let symbol = props.compnay_symbol.toLowerCase();
  // console.log(symbol, compId);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();

  useEffect(() => {
    fetch(`${baseUrl}/publishreports/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReportData(data);
        // console.log('ab change howa hai', data);
        setIsLoading(true);
      });
  }, [compId]);

  if (isLoading) {
    return (
      <div className="publish-report-block col-12 p-0">
        <div className="title-block block-head-bg-dark">
          <h4>Published Report</h4>
        </div>
        <div className="col-12 data-block d-flex flex-wrap justify-content-between">
          {reportData.map((value) => {
            return (
              <div class="row report">
                <div class="img">
                  <img
                    src={`https://uploader.akseerresearch.com/logo/${symbol}.svg`}
                    alt={symbol}
                  />
                  {/* <img src={Thumbnail} /> */}
                </div>
                <div class="content">
                  <div class="text">{value.title}</div>
                  <div class="calendar-icon">
                    <span>
                      <svg
                        width="8"
                        height="12"
                        viewBox="0 0 8 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.96507 5.09583H2.72642V6.22824H1.96507V5.09583ZM7.29448 2.26481V10.1917C7.29448 10.8145 6.95188 11.3241 6.53314 11.3241H1.20373C1.00181 11.3241 0.808155 11.2048 0.665375 10.9924C0.522596 10.78 0.442383 10.492 0.442383 10.1917L0.44619 2.26481C0.44619 1.64199 0.781181 1.13241 1.20373 1.13241H1.5844V0H2.34574V1.13241H5.39112V0H6.15247V1.13241H6.53314C6.95188 1.13241 7.29448 1.64199 7.29448 2.26481ZM1.20373 3.39722H6.53314V2.26481H1.20373V3.39722ZM6.53314 10.1917V4.52963H1.20373V10.1917H6.53314ZM5.01045 6.22824H5.77179V5.09583H5.01045V6.22824ZM3.48776 6.22824H4.24911V5.09583H3.48776V6.22824Z"
                          fill="#555555"
                        />
                      </svg>
                    </span>
                    <span>{value.created_at}</span>
                  </div>
                  <div class="description1">{value.content}</div>
                </div>
                <div class="pdficon">
                  <Link
                    to={{ pathname: `${value.file}` }}
                    target="_blank"
                    download
                  >
                    <div dangerouslySetInnerHTML={{ __html: pdf }} />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-12">
          <a>
            <p class="text-right view-all">View All</p>
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div className="publish-report-block col-12 p-0">
        <div className="title-block block-head-bg-dark">
          <h4>Published Report</h4>
        </div>
      </div>
    );
  }
}

export default PublishReport;
