import React from 'react';
import SettlementOld from './settlementOld';
import SettlementValue from './settlementValue';
import SettlementVolume from './settlementVolume';
import KSEChart from './kseChart';
import CompFinancials from './compFinancial';
import IndustryAvg from './industryAvg';
import CompanyInfo from './companyInfo';
import FinancialCharts from './financials';
// import TimelineTabs from './timeline/index';
import './overview.css';

const Overview = (props) => {
  // console.log("Overview ==========>", props.company);

  const settlementChartsValue = [
    /* {
      id: 's1',
      value: 'Closing Price',
      color: '0B254A',
      name: 'Closing Price',
    }, */
    {
      id: 's2',
      value: 'Value',
      color: '3969D5',
      name: 'Settlement Ratio',
    },
    {
      id: 's3',
      value: 'total_value',
      color: 'B7CD5E',
      name: 'Traded Value',
    },
  ];

  const settlementChartsVolume = [
    /* {
      id: 's1',
      value: 'Closing Price',
      color: '0B254A',
      name: 'Closing Price',
    }, */
    {
      id: 's2',
      value: 'Vol',
      color: '3969D5',
      name: 'Settlement Ratio',
    },
    {
      id: 's3',
      value: 'total_volume',
      color: 'B7CD5E',
      name: '',
    },
  ];
  return (
    <>
      <div className="overview-main">
        <div className="d-flex flex-wrap justify-content-between">
          {/* <div className='col-xl-12 d-flex p-0'>
                        <div className='technical-block col-lg-12 p-0'>
                            <div className='title-block'>
                                <h4>Technical Chart</h4>
                            </div>
                            <div className='col-12 data-block d-flex flex-wrap justify-content-between'>
                                <Technical company={props.company}/>
                            </div>
                        </div>
                    </div> */}
          <div className="col-xl-7 d-flex flex-wrap justify-content-between p-0">
            <div className="col-xl-9 p-0 pr-lg-2">
              <div className="comp-financial-block">
                {/* <div className="title-block">
                  <h4>Company Financials</h4>
                </div> */}
                <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
                  <CompFinancials
                    company={props.company}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 p-0">
              <div className="industry-avg-block">
                {/* <div className="title-block">
                  <h4>Industry Average</h4>
                </div> */}
                <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
                  <IndustryAvg
                    industryData={props.industryData ? props.industryData : []}
                    company={props.company}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 d-flex flex-wrap justify-content-between px-0 pl-xl-2">
            {/*<div className="settlement-block col-12 p-0">
              <div className="title-block">
                <h4>Settlement ratio</h4>
              </div>
              <div className="col-12 data-block d-flex flex-wrap justify-content-between">
                <Settlement company={props.company} />
              </div>
            </div>*/}
            <div className="ffl-block col-12 p-0">
              <div className="title-block block-head-bg-dark">
                <h4>
                  {props.company.symbol} Relative Returns against KSE100 Index
                  Returns (%)
                </h4>
              </div>
              <div className="col-12 data-block d-flex flex-wrap justify-content-between">
                <KSEChart company={props.company} />
              </div>
            </div>
          </div>
          {/*<div className="col-lg-12 p-0">
            <div className="cirdci-block d-flex flex-wrap justify-content-between">
              <CompanyInfo company={props.company} />
            </div>
          </div>*/}
          <div className="col-lg-12 p-0"></div>

          <div className="col-lg-12 p-0">
            <div className="col-xl-6 p-0 left">
              <div className="col-xl-12 d-flex flex-wrap justify-content-between px-0 pr-xl-1">
                <div className="settlement-block col-12 p-0">
                  <div className="title-block block-head-bg-dark">
                    <h4>Settlement Ratio (Value)</h4>
                  </div>
                  <div className="col-12 data-block d-flex flex-wrap justify-content-between">
                    {settlementChartsValue.map((s) => {
                      return (
                        <SettlementValue
                          company={props.company}
                          chartId={s.id}
                          chartValue={s.value}
                          chartColor={s.color}
                          legendName={s.name}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="clearfix"></div> */}

            <div className="col-xl-6 p-0 right">
              <div className="col-xl-12 d-flex flex-wrap justify-content-between px-0 pl-xl-1">
                <div className="settlement-block col-12 p-0">
                  <div className="title-block block-head-bg-dark">
                    <h4>Settlement Ratio (Volume)</h4>
                  </div>
                  <div className="col-12 data-block d-flex flex-wrap justify-content-between">
                    {settlementChartsVolume.map((s) => {
                      return (
                        <SettlementVolume
                          company={props.company}
                          chartId={s.id}
                          chartValue={s.value}
                          chartColor={s.color}
                          legendName={s.name}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-12 p-0">
                        <div className="timeline-tab-block">
                            <TimelineTabs handleRecent={props.handleRecent}/>
                        </div>
                    </div>  */}
          <div className="col-lg-12 p-0">
            <div className="financial-chart-block d-flex flex-wrap justify-content-between">
              <FinancialCharts company={props.company} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
