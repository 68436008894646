import React, { useState, useEffect } from 'react';
import Header from '../common/header/header';
import Search from '../common/search/search';
import loaderDots from '../assets/media/loading-dots.svg';
import LoadingImage from '../assets/media/loader.gif';
import loadingSmallImage from '../assets/media/loading-small.gif';
import NotificationAlert from '../common/notificationAlert/notificationAlert';
import Valuation from './valuationData';
import StockData from './stockData';
import TabsView from './tabs/tabsView';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './dashboard.css';
import CustomSearchBar from '../components/CustomSearchBar/CustomSearchBar';
import {
  setAllData as _setAllData,
  setSelectedData as _setSelectedData,
} from '../redux/actions/SearchActions';
import { useDispatch } from 'react-redux';
import { getAuth } from '../redux/actions/AuthActions';
import { Redirect, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import IdleTimer from '../IdleTimerContainer';
import Clock from 'react-live-clock';
import SessionExpire from '../layout/login/sessionExpire';
import '../assets/media/loader.gif';
import { baseUrl } from '../api/baseUrl';
const Dashboard = () => {
  const [searchItems, setSearchItems] = useState();
  const [searchSelected, setSearchSelected] = useState();
  const [allData, setAllData] = useState();
  const [alert, setAlert] = useState(false);
  const [industryData, setIndustryData] = useState();
  const [valuateShareData, setValuateShareData] = useState();
  const [cookies, setCookies, removeCookies] = useCookies();
  const [selectedCompany, setSelectedCompany] = useState({});
  const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [dark, setDark] = useState(false);
  const [sessionMessage, setSessionMessage] = useState('');
  const [sessionModal, setSessionModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCompanyData = useSelector((store) => store.searchData.allData);
  const accessToken = cookies.accessToken;

  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${cookies?.accessToken}`;

  const closeAlert = () => {
    setAlert(false);
  };

  if (alert === true) {
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  }

  /* Session expiry functionality start */

  const sessionTimer = localStorage.getItem('sessionTime');

  function sessionTimeout() {
    setSessionModal(false);
    removeCookies('accessToken');
    removeCookies('user');
    removeCookies('userInformation');
    localStorage.removeItem('sessionTime');
    history.replace('/');
  }

  var count = sessionTimer * 1000;
  setTimeout(() => {
    setSessionMessage('Your session has timed out. Please login again.');
    handleSession();
  }, 3600000);

  const handleSession = () => {
    setSessionModal(true);
  };

  const closeModal = () => {
    setSessionModal(false);
    sessionTimeout();
  };

  /* Session expiry functionality end */

  const getData = () => {
    const defaultCompany = axios.get(`${baseUrl}/default/company`, {
      header: {
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    });

    axios
      .all([defaultCompany])
      .then(
        axios.spread((...responses) => {
          const defaultData = responses[0].data.api;
          setAllData(defaultData);
          dispatch(_setAllData(defaultData));
          setSearchSelected(defaultData.company[0]);
        })
      )
      .catch((error) => {
        console.log(error, 'error in simultaneaous request...');
      });
  };

  useEffect(() => {
    dispatch(getAuth());
    handleOnSearch();
    getData();
  }, []);

  const handleOnSearch = () => {
    axios
      .post(
        `${baseUrl}/search/suggestions`,
        {
          params: {
            _limit: 5,
          },
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(function (response) {
        setSearchItems(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleOnHover = (result) => {};

  const handleOnSelect = (item) => {
    setSelectedCompany(item);
    dispatch(_setSelectedData(item));
    setSearchSelected(item);
    axios
      .post(
        `${baseUrl}/searched/company`,
        {
          search: item.id,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${cookies?.accessToken}`,
          },
        }
      )
      .then(function (response) {
        setSearchItems(response.data);
        setAllData(response.data.api);
      })
      .catch(function (error) {
        setAlert(true);
      });
  };

  const __handleOnSelect = (item) => {
    // console.log('Item here', item);
    setValuateShareData(null);
    setFetchingDataLoader(true);
    dispatch(_setSelectedData(item));
    const searchCompany = axios.post(
      `${baseUrl}/searched/company`,
      {
        search: item.id,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${cookies?.accessToken}`,
        },
      }
    );

    axios
      .all([searchCompany])
      .then(
        axios.spread((...responses) => {
          const searchCompanyData = responses[0].data.api;
          /* console.log(
            searchCompanyData,
            'defaultComp axios simultaneaous ====>'
          ); */

          setSearchSelected(item);
          setSearchItems(responses[0].data);
          setAllData(searchCompanyData);
          // console.log('all data is here', allData['company'][0]);
          setFetchingDataLoader(false);
          setSelectedCompany(searchCompanyData.company[0]);
          setLoadingFlag(true);
          // setIndustryData(industryData);
        })
      )
      .catch((error) => {
        console.log(error, 'error in simultaneaous request...');
        setFetchingDataLoader(false);
        setAlert(true);
      });
  };

  // ==============================

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    return (
      <p
        dangerouslySetInnerHTML={{ __html: '<strong>' + item + '</strong>' }}
      ></p>
    ); //To format result as html
  };

  const handleQuerySelect = (id, value) => {
    __handleOnSelect(id, value);
  };

  if (!accessToken) {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  } else {
    return (
      <>
        <IdleTimer
          handleSession={handleSession}
          setSessionMessage={setSessionMessage}
        />
        <Header dark={dark} setDark={setDark} />
        <div
          className={dark === true ? 'dashboard-main dark' : 'dashboard-main'}
        >
          {searchItems && allData !== undefined ? (
            <>
              <div className="d-flex align-items-center">
                {searchItems ? (
                  <CustomSearchBar
                    suggestions={searchItems ? searchItems : [{}]}
                    handleQuerySelect={handleQuerySelect}
                  />
                ) : (
                  <img src={LoadingImage} />
                )}
                <div>
                  {fetchingDataLoader ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <h5
                        className="ml-3"
                        style={{ fontWeight: 'bold', color: '#3969d5' }}
                      >
                        Fetching Data
                      </h5>{' '}
                      <img style={{ width: '100px' }} src={loaderDots} />{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              {alert === true ? (
                <NotificationAlert
                  message="No Record Found"
                  className="p-0"
                  color="alert"
                  closeAlert={closeAlert}
                />
              ) : null}
              <Valuation
                valuateShareData={valuateShareData ? valuateShareData : null}
                loadingFlag={loadingFlag}
                setLoadingFlag={setLoadingFlag}
                allData={allData}
                alert={alert}
                searchSelected={searchSelected}
                company={searchSelected ? searchSelected : ''}
              />
              <StockData
                // allData={allData}
                company={searchSelected ? searchSelected : ''}
              />
              <TabsView
                industryData={industryData ? industryData : []}
                allData={allData}
                company={searchSelected ? searchSelected : ''}
              />
            </>
          ) : (
            <div
              style={{ width: '100%', height: '100%', color: '#426fd7' }}
              className="main-loader d-flex flex-column justify-content-center align-items-center"
            >
              <img src={LoadingImage} style={{ height: '500px' }} />
            </div>
          )}
        </div>
        <SessionExpire
          sessionModal={sessionModal}
          closeModal={closeModal}
          sessionMessage={sessionMessage}
        />
      </>
    );
  }
};

export default Dashboard;
