import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import askanalystlogo from '../../assets/media/images/askanalyst.png';
import alphaanalystlogo from '../../assets/media/images/alphaanalyst.svg';
import UserImg from '../../assets/media/images/profile_user.jpg';
import { Cookies, useCookies } from 'react-cookie';
// import { useHistory } from 'react-router-dom';
import './header.css';
import { Redirect, useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

const Navbar = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [userAction, setUserAction] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies([
    'accessToken',
    'user',
  ]);

  let logo;
  let financialTab = true;
  if (window.location.host === 'analyst.alphacapital.com.pk') {
    logo = alphaanalystlogo;
    financialTab = false;
  } else if (window.location.host === 'askanalyst.akseerresearch.com') {
    logo = askanalystlogo;
    financialTab = true;
  } else {
    logo = askanalystlogo;
    financialTab = true;
  }

  // const [pageTitle , setPageTitle] = useState(titleText);

  // const [userInfo, setUserInfo] = useState(cookies.userInformation);

  // console.log("User Information in header", cookies);
  const userInfo = JSON.parse(localStorage.getItem('userActive'));
  // console.log(userInfo , "userInfo");

  const userInitial = userInfo.name.split('')[0];

  // console.log(userInfo.name.split('')[0] , "first letter");

  const handleUserAction = () => {
    setUserAction(!userAction);
  };

  const handleLogout = () => {
    removeCookies('accessToken');
    removeCookies('user');
    removeCookies('userInformation');
    return <Redirect to="/" />;
  };

  const handleReset = () => {
    var accessTokenObj = JSON.parse(localStorage.getItem('userActive'));
    history.push(`/reset_password?token=${accessTokenObj.token}`);
  };

  const showMyCookies = () => {
    console.log('showing coookies from header', cookies);
  };

  useEffect(() => {
    //   setUserInfo(cookies.userInformation);
  });

  //   const activeClass = location.pathname == '/dashboard' ? 'active':'';
  // console.log(location.pathname);

  return (
    <>
      {/* <title>{pageTitle}</title> */}
      <div className={props.dark ? 'header dark' : 'header'}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <Container fluid>
            <Row className="justify-content-between w-100 m-0">
              <Col md={2} xs={6} className="">
                <div className="navbar-brand">
                  <img src={logo} alt="logo" className="imglogo" />
                </div>
              </Col>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link
                      className={
                        'nav-link ' +
                        (location.pathname == '/dashboard' ? 'active' : '')
                      }
                      to="dashboard"
                    >
                      <button
                        type="button"
                        id="controlled-tab-example-tab-technical"
                        role="tab"
                        data-rr-ui-event-key="technical"
                        aria-controls="controlled-tab-example-tabpane-technical"
                        tabindex="-1"
                      >
                        Dashboard
                      </button>
                    </Link>
                  </li>
                  {financialTab ? (
                    <li className="nav-item">
                      <Link
                        className={
                          'nav-link ' +
                          (location.pathname == '/financials' ? 'active' : '')
                        }
                        to="financials"
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabindex="-1"
                        >
                          Financial Models
                        </button>
                      </Link>
                    </li>
                  ) : (
                    ''
                  )}

                  <li className="nav-item">
                    <Link
                      className={
                        'nav-link ' +
                        (location.pathname == '/sector' ? 'active' : '')
                      }
                      to="sector"
                    >
                      <button
                        type="button"
                        id="controlled-tab-example-tab-technical"
                        role="tab"
                        data-rr-ui-event-key="technical"
                        aria-controls="controlled-tab-example-tabpane-technical"
                        tabindex="-1"
                      >
                        Sector Fundamentals
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>

              <Col
                md={2}
                xs={6}
                className="d-flex align-items-center justify-content-end pr-0"
              >
                <div
                  onMouseEnter={handleUserAction}
                  onMouseLeave={handleUserAction}
                  className={userAction ? 'user-option active' : 'user-option'}
                >
                  <p className="inline-block user-img">
                    {/* <button className="btn btn-primary" onClick={showMyCookies}>Show Cookies</button> */}
                    {userInfo.name !== '' ? (
                      <span className="userInitials">{userInitial}</span>
                    ) : (
                      <img src={UserImg} alt="user" />
                    )}
                    <span
                      id="user-trigger"
                      className="user-trigger d-inline-block"
                    >
                      <svg
                        width="20px"
                        height="12px"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="angle-down"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        className="svg-inline--fa fa-angle-down fa-w-10"
                      >
                        <path
                          fill="#767676"
                          d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"
                          className=""
                        ></path>
                      </svg>
                    </span>
                  </p>
                  <ul className="list-unstyled m-0">
                    <li className="option-item">
                      <div
                        style={{ marginTop: '10px' }}
                        className="d-flex flex-column"
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '18px',
                            margin: '0px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {userInfo.name}
                        </p>
                        <p style={{ color: '#00000094' }} className="mb-1 p-0">
                          {userInfo.email}
                        </p>
                      </div>
                    </li>
                    {/* {<li className="option-item">
                                        <p className="m-0">
                                            <span className="icon">
                                                <svg width="20px" height="20px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-eye fa-w-18">
                                                    <path fill="#767676" d="M288 288a64 64 0 0 0 0-128c-1 0-1.88.24-2.85.29a47.5 47.5 0 0 1-60.86 60.86c0 1-.29 1.88-.29 2.85a64 64 0 0 0 64 64zm284.52-46.6C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 96a128 128 0 1 1-128 128A128.14 128.14 0 0 1 288 96zm0 320c-107.36 0-205.46-61.31-256-160a294.78 294.78 0 0 1 129.78-129.33C140.91 153.69 128 187.17 128 224a160 160 0 0 0 320 0c0-36.83-12.91-70.31-33.78-97.33A294.78 294.78 0 0 1 544 256c-50.53 98.69-148.64 160-256 160z" className=""></path>
                                                </svg>
                                            </span>
                                            View Profile
                                        </p>
                                    </li> } */}
                    {/* <li className="option-item">
                                        <p className="m-0"  onClick={handleReset}>
                                            <span className="icon">
                                                <svg width="20px" height="20px" aria-hidden="true" focusable="false" viewBox="0 0 24 24" data-testid="LockOutlinedIcon" aria-label="fontSize large" className="svg-inline--fa fa-sign-out fa-w-16" >
                                                    <path fill="#767676" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path>
                                                </svg>
                                            </span>
                                            Reset Password
                                        </p>
                                    </li> */}
                    <li className="option-item">
                      <p className="m-0" onClick={handleLogout}>
                        <span className="icon">
                          <svg
                            width="20px"
                            height="20px"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fal"
                            data-icon="sign-out"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="svg-inline--fa fa-sign-out fa-w-16"
                          >
                            <path
                              fill="#767676"
                              d="M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z"
                              className=""
                            ></path>
                          </svg>
                        </span>
                        Logout
                      </p>
                    </li>
                  </ul>
                </div>
                {/* <div className='theme-mode mr-2 d-flex flex-wrap justify-content-center align-items-center'>
                                <button onClick={() => props.setDark(!props.dark)}>
                                    {props.dark === true ? 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 30 30" width="15px" height="15px"><path d="M 14.984375 0.98632812 A 1.0001 1.0001 0 0 0 14 2 L 14 5 A 1.0001 1.0001 0 1 0 16 5 L 16 2 A 1.0001 1.0001 0 0 0 14.984375 0.98632812 z M 5.796875 4.7988281 A 1.0001 1.0001 0 0 0 5.1015625 6.515625 L 7.2226562 8.6367188 A 1.0001 1.0001 0 1 0 8.6367188 7.2226562 L 6.515625 5.1015625 A 1.0001 1.0001 0 0 0 5.796875 4.7988281 z M 24.171875 4.7988281 A 1.0001 1.0001 0 0 0 23.484375 5.1015625 L 21.363281 7.2226562 A 1.0001 1.0001 0 1 0 22.777344 8.6367188 L 24.898438 6.515625 A 1.0001 1.0001 0 0 0 24.171875 4.7988281 z M 15 8 A 7 7 0 0 0 8 15 A 7 7 0 0 0 15 22 A 7 7 0 0 0 22 15 A 7 7 0 0 0 15 8 z M 2 14 A 1.0001 1.0001 0 1 0 2 16 L 5 16 A 1.0001 1.0001 0 1 0 5 14 L 2 14 z M 25 14 A 1.0001 1.0001 0 1 0 25 16 L 28 16 A 1.0001 1.0001 0 1 0 28 14 L 25 14 z M 7.9101562 21.060547 A 1.0001 1.0001 0 0 0 7.2226562 21.363281 L 5.1015625 23.484375 A 1.0001 1.0001 0 1 0 6.515625 24.898438 L 8.6367188 22.777344 A 1.0001 1.0001 0 0 0 7.9101562 21.060547 z M 22.060547 21.060547 A 1.0001 1.0001 0 0 0 21.363281 22.777344 L 23.484375 24.898438 A 1.0001 1.0001 0 1 0 24.898438 23.484375 L 22.777344 21.363281 A 1.0001 1.0001 0 0 0 22.060547 21.060547 z M 14.984375 23.986328 A 1.0001 1.0001 0 0 0 14 25 L 14 28 A 1.0001 1.0001 0 1 0 16 28 L 16 25 A 1.0001 1.0001 0 0 0 14.984375 23.986328 z"/></svg>
                                    :
                                        <svg width="15px" height="15px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="moon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-moon fa-fw fa-xl"><path fill="#396dd5" d="M32 256c0-123.8 100.3-224 223.8-224c11.36 0 29.7 1.668 40.9 3.746c9.616 1.777 11.75 14.63 3.279 19.44C245 86.5 211.2 144.6 211.2 207.8c0 109.7 99.71 193 208.3 172.3c9.561-1.805 16.28 9.324 10.11 16.95C387.9 448.6 324.8 480 255.8 480C132.1 480 32 379.6 32 256z" className=""></path></svg>
                                    }
                                </button>
                            </div> */}
              </Col>
            </Row>
          </Container>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
