import React, { useEffect, useState } from 'react';
import './income.css';
import { minusValueCheckGlobal } from '../../../GlobalFunctions';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import { useCookies } from 'react-cookie';
// import LoadingImage from '../../../assets/media/loader.gif';
import LoadingImage from '../../../assets/media/loader.gif';
import { baseUrl } from '../../../api/baseUrl';

const IncomeTable = (props) => {
  let compId = props.company.id;
  const [incomeStatement, setIncomeStatement] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  // setValData(props.allData);
  useEffect(() => {
    fetch(`${baseUrl}/incomestatement/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIncomeStatement(data);
        setIsLoading(true);
        // console.log(data, 'ffls123 response');
        // generateChart(data);
      });
  }, [props.company.id]);
  let boldHeadings = [
    'Gross profit',
    'Operating profit',
    'EBITDA',
    'EPS - Diluted',
    'DPS',
    'Profit before tax',
    'Profit after tax',
  ];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };

  if (incomeStatement.length && isLoading) {
    return (
      <div className="income-main">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={2}>Income Statement - PKR (mn)</th>
              {incomeStatement[0].data
                .slice(-11)
                .map((y, i) => (i !== 11 ? <th>{y.year}</th> : null))}
            </tr>
          </thead>
          <tbody>
            {incomeStatement.map((r) => {
              if (headingCheck(r.label)) {
                return (
                  <tr>
                    <td style={{ fontWeight: '800' }} colSpan={2}>
                      {r.label}
                    </td>
                    {r.data.slice(-11).map((d, i) => {
                      return (
                        // (i !== 10) ? <td>{valueCheckGlobal(d.value)}</td> : null
                        i !== 11 ? (
                          <td style={{ fontWeight: '800' }}>
                            {minusValueCheckGlobal(d.value)}
                          </td>
                        ) : null
                      );
                    })}
                  </tr>
                );
              }
              return (
                <tr>
                  <td colSpan={2}>{r.label}</td>
                  {r.data.slice(-11).map((d, i) => {
                    return (
                      // (i !== 10) ? <td>{valueCheckGlobal(d.value)}</td> : null
                      i !== 11 ? (
                        <td>{minusValueCheckGlobal(d.value)}</td>
                      ) : null
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div
        style={{ width: '100%', height: '100%', color: '#426fd7' }}
        className="main-loader d-flex flex-column justify-content-center align-items-center"
      >
        <img src={LoadingImage} style={{ height: '500px' }} />
      </div>
    );
  }
};

export default IncomeTable;
