import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FindEnterpriseValue,
  FindMarketCap,
  valueCheckGlobal,
} from '../GlobalFunctions';
import Loader from '../assets/media/_loader.gif';
import Clock from 'react-live-clock';

const ValuationData = ({
  allData,
  alert,
  searchSelected,
  company,
  setLoadingFlag,
  loadingFlag,
  valuateShareData,
}) => {
  let comp_symbol = '';
  const [seconds, setSeconds] = useState(0);
  const [marketCap, setMarketCap] = useState(0);
  const [enterpriseValue, setEnterpriseValue] = useState(0);
  const [updatedData, setUpdatedData] = useState();
  const [lastUpdatedData, setLastDataUpdated] = useState([{}]);
  const [valuationData, setValuationData] = useState(valuateShareData);

  useEffect(() => {
    // console.log('valuation k ander');
    // console.log("valuation data ander", valuateShareData);
    // console.log("company valuation ander", company.id);
    setValuationData(valuateShareData);
    /* const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 60 * 60 * 1000 ); */

    const interval2 = axios
      .post(
        'https://askanalyst-testing.akseerresearch.com/api/sharepricedata',
        {
          company_id: company.id,
        },
        {
          header: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((response) => {
        // console.log(response.data[0], 'valuation after api');
        setValuationData(response.data[0]);
      })
      .catch((err) => {
        console.log('VALUATION API ===>', err);
      });
    return () => clearInterval(interval2);
  }, [company.id, valuateShareData]);

  // useEffect(() => {

  //     const interval = setInterval(() => {

  //     fetch("https://askanalyst.akseerprojects.com/api/data", {
  //         method: "POST",
  //         headers: {
  //             "Content-type": "application/json; charset=UTF-8"
  //         }
  //     })
  //     .then(response => response.json())
  //     .then( response => {
  //         console.log(response);
  //         setWatchTabledata(response);
  //     })
  //     .catch( err => console.log("There was an while fetching table data", err) );
  //       setSeconds(seconds => seconds + 1);
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }, []);

  // console.log(valuationData);

  // console.log("LAST PRICE =================>", valuationData.current_price)
  // useEffect(() => {
  //     let comp_id = company.id;
  //     console.log("Comapny idd ==========>", comp_id)
  //     const interval = setInterval(() => {
  //         axios.post('https://askanalyst-testing.akseerresearch.com/api/sharepricedata',{
  //             company_id: comp_id,
  //         },
  //         {
  //             headers: {"Access-Control-Allow-Origin": "*",
  //                       "Content-Type":  "application/json",

  //         }
  //         })
  //         .then(function (response) {
  //             // console.log(response.data[0] , "response here...");
  //             console.log("NEW STOCk PRICE API =====>", response);
  //             setLastDataUpdated(() => {
  //                 setLoadingFlag(false);
  //                 return (response.data[0]);
  //             });
  //             setUpdatedData(() => {
  //                 setLoadingFlag(false);
  //                 return (
  //                     (response.data[0]) ? response.data[0] : lastUpdatedData
  //                 )
  //             });
  //         })
  //         .catch(function (error) {
  //             console.log(error);
  //         });
  //         setSeconds(seconds => seconds + 1);
  //     }, 30000);
  //     return () => clearInterval(interval);
  // });

  // if(valuationData) {
  return (
    <>
      <div className="main-box main-title-dashboad">
        <div className="title-block d-flex flex-wrap justify-content-between align-items-center">
          <h1>
            {company.name} <span className="symbol">({company.symbol})</span>
            <span className="sector">{company.sector}</span>
          </h1>
          <Clock
            format={'DD MMMM YYYY, h:mm:ss A'}
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#3d3d3d',
              padding: '0 10px',
            }}
            ticking={true}
          />
        </div>
      </div>
      <div className="valuation-box main-box">
        <div className="title-block">
          {/* <h4 className="d-flex flex-wrap justify-content-between">
            <span>Valuation and Share Price Performance - Dashboard</span>
          </h4> */}
          <p className="d-flex flex-wrap justify-content-between">
            <span>
              Prices as of{' '}
              {valuationData && valuationData.current_price.trade_date
                ? valuationData.current_price.trade_date
                : ''}
            </span>
          </p>
        </div>
        <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
          {valuationData ? (
            <>
              <div className="col-lg-6 px-0">
                <div className="high-low-current d-flex flex-wrap justify-content-between align-items-center">
                  <div className="col-lg-6 h-100 head d-flex flex-wrap justify-content-center align-items-center">
                    <div className="current-inner">
                      <div className="current-price w-100 d-flex justify-content-center align-items-center">
                        <div className="d-inline-flex flex-wrap">
                          <p className="my-1 text">
                            Price
                            {/* (valuationData.current_price && valuationData.current_price.change_in_rate >= 0)  ? 
                                                                <span className='plus'>
                                                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.49992 6.49976V0.499756" stroke="#6BAB04" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path d="M0.874847 3.12476L3.49985 0.499756L6.12485 3.12476" stroke="#6BAB04" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>
                                                                    {(valuationData.current_price.change_in_rate) ? valuationData.current_price.change_in_rate : "" }
                                                                </span>
                                                            : (valuationData.current_price && valuationData.current_price.change_in_rate < 0) ? 
                                                                <span className='minus'>
                                                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.49992 6.49976V0.499756" stroke="#ff0000" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path d="M0.874847 3.12476L3.49985 0.499756L6.12485 3.12476" stroke="#ff0000" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>
                                                                    {(valuationData.current_price.change_in_rate) ? valuationData.current_price.change_in_rate : "" }
                                                                </span>
                        : "" */}
                            <span
                              className={
                                valuationData.current_price &&
                                valuationData.current_price.percentage_change <
                                  0
                                  ? 'percentage-change down'
                                  : 'percentage-change up'
                              }
                            >
                              {valuationData.current_price.percentage_change
                                ? `(${valuationData.current_price.percentage_change.toFixed(
                                    2
                                  )}%)`
                                : ''}
                            </span>
                          </p>
                          <p className="my-1 price">
                            <b>
                              {valuationData.current_price ? (
                                `PKR ${valuationData.current_price.unadjusted_price}`
                              ) : (
                                <img
                                  style={{ width: '50px' }}
                                  src={Loader}
                                ></img>
                              )}{' '}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 h-100 p-0 pr-xl-1">
                    <div className="high-low-inner">
                      <div className="high-price odd d-flex justify-content-start align-items-center">
                        <p className="my-1">
                          <span className="text">52 Weeks High</span>
                          <span className="price">
                            {' '}
                            {valuationData.high
                              ? `PKR ${valueCheckGlobal(valuationData.high)}`
                              : '0'}
                          </span>
                        </p>
                      </div>
                      <div className="low-price even d-flex justify-content-start align-items-center">
                        <p className="my-1">
                          <span className="text">52 Weeks Low</span>
                          <span className="price">
                            {valuationData.low
                              ? `PKR ${valueCheckGlobal(valuationData.low)}`
                              : '0'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-0  pl-xl-1 side-figures">
                <ul className="list-unstyled m-0">
                  <li className="odd">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Enterprise Value{' '}
                        <span className="table-unit-col">(PKR-mn)</span>{' '}
                      </span>
                      <span className="data">
                        {valuationData.ev
                          ? `${valueCheckGlobal(valuationData.ev)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="odd">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Market Cap{' '}
                        <span className="table-unit-col">(PKR - mn)</span>
                      </span>
                      <span className="data">
                        {valuationData.market_cap
                          ? `${valueCheckGlobal(valuationData.market_cap)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="even">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Total Debt{' '}
                        <span className="table-unit-col">(PKR-mn)</span>
                      </span>
                      <span className="data red">
                        {valuationData.total_debt
                          ? `${valueCheckGlobal(valuationData.total_debt)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="even">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Cash <span className="table-unit-col">(PKR-mn)</span>
                      </span>
                      <span className="data">
                        {valuationData.cash
                          ? `${valueCheckGlobal(valuationData.cash)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="no-border odd">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Debt / EV <span className="table-unit-col">(%)</span>{' '}
                      </span>
                      <span className="data">
                        {valuationData.debt_ev
                          ? valueCheckGlobal(valuationData.debt_ev)
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="no-border odd">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Debt / Total Capital{' '}
                        <span className="table-unit-col">(%)</span>
                      </span>
                      <span className="data">
                        {valuationData.debt_totalcapital
                          ? valueCheckGlobal(valuationData.debt_totalcapital)
                          : '0'}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>{' '}
            </>
          ) : (
            // : "Loading . . . ."}
            <div
              style={styles.noContent}
              className="no-content-load text-center"
            >
              <img style={styles.noContentImage} src={Loader} alt="" />
            </div>
          )}
          {/* <div style={styles.noContent} className='no-content-load text-center'>
                            <img style={styles.noContentImage} src={Loader} alt="" />
                        </div> */}
        </div>
      </div>
    </>
  );
  // } else {
  //     return(<h1>Loading . . . . </h1>)
  // }
};
export default ValuationData;

const styles = {
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
