
import { ActionTypes } from "../constants/ActionTypes"

export const setAuth = (auth) => {
    return {
        type:ActionTypes.SET_AUTH,
        payload: auth
    }
}

export const getAuth = (auth) => {
    return {
        type: ActionTypes.GET_AUTH,
        payload: auth
    }
}