import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Timeline from './Tabs/Timeline';
import { useCookies } from 'react-cookie';
import { baseUrl, pdf, icon } from '../../../api/baseUrl';

function RecentDevelopment(props) {
  const [activeTab, setActiveTab] = useState('capex');
  let compId = props.compnay_id;
  // let symbol = props.compnay_symbol.toLowerCase();
  console.log('here', compId);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();

  useEffect(() => {
    fetch(`${baseUrl}/recentdevelopments/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setReportData(data);
        setIsLoading(true);
      });
  }, [compId]);
  if (isLoading) {
    return (
      <div className="publish-report-block col-12 p-0">
        <div className="title-block block-head-bg-dark">
          <h4>Recent Development</h4>
        </div>
        <div className="col-12 data-block d-flex flex-wrap justify-content-between">
          <>
            <div className="rd-view">
              <Tabs
                id="controlled-tab-example"
                defaultActiveKey="capex"
                className="recent-development-tabs"
              >
                <Tab eventKey="capex" title="Capex">
                  <Timeline timelines={reportData[0].capex} icon={icon} />
                </Tab>
                <Tab eventKey="other" title="Other Information">
                  <Timeline
                    timelines={reportData[1].other_information}
                    icon={icon}
                  />
                </Tab>
                <Tab eventKey="payouts" title="Payouts">
                  {/* if(reportData[2].Payout.lenght) */}
                  {<Timeline timelines={reportData[2].Payout} icon={icon} />}
                </Tab>
                <Tab eventKey="volume" title="Volume">
                  <Timeline timelines={reportData[3].volume} icon={icon} />
                </Tab>
                <Tab eventKey="pricemargin" title="Price & Margin">
                  <Timeline
                    timelines={reportData[4].price_and_margin}
                    icon={icon}
                  />
                </Tab>
                <Tab eventKey="all" title="All">
                  <Timeline timelines={reportData[5].all} icon={icon} />
                </Tab>
              </Tabs>
            </div>
          </>
        </div>
      </div>
    );
  } else {
    return (
      <div className="publish-report-block col-12 p-0">
        <div className="title-block block-head-bg-dark">
          <h4>Recent Development</h4>
        </div>
      </div>
    );
  }
}

export default RecentDevelopment;
