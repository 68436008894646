import React from 'react';

function Heading5(props) {
  return (
    <div className="revenue-drivers-block">
      <div className="title-block block-head-bg-dark">
        <h4>heading 5</h4>
      </div>
      <div className="col-12 p-0 data-block flex-wrap justify-content-between"></div>
    </div>
  );
}

export default Heading5;
