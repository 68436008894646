import React, { useEffect, useState } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import axios from 'axios';
import './ratio.css';
import LoadingImage from '../../../assets/media/loader.gif';
import { baseUrl } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';

const RatioTable = (props) => {
  let compId = props.company.id;
  // console.log('i am here1', compId);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  // setValData(props.allData);
  const [valData, setValData] = useState([]);
  useEffect(() => {
    fetch(`${baseUrl}/ratio/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValData(data);
        setIsLoading(true);
        // console.log(data, 'ffls1234 response');
        // generateChart(data);
      });
  }, [props.company.id]);

  let name = {
    cash_share: 'Cash/Share',
    net_debt: 'Net Debt',
    debt_to_capital: 'Debt to Capital',
    equity_to_capital: 'Equity to Capital',
    current_ratio: 'Current Ratio',
    gross_margin: 'Gross Margin',
    ebidta_margin: 'EBITDA Margin',
    operating_margin: 'Operating Margin',
    net_margin: 'Net Margin',
    revenue_growth: 'Revenue Growth',
    gross_profit_growth: 'Gross Profit growth',
    ebidta_profit_growth: 'EBITDA Growth',
    operating_profit_growth: 'Operating Profit Growth',
    profit_before_tax_growth: 'Pre-Tax Profit Growth',
    net_profit_growth: 'Net profit Growth',
    roe: 'ROE',
    roa: 'ROA',
    eps: 'EPS - (adj)',
    dps: 'DPS - (adj)',
    bvps: 'BVPS - (adj)',
    bonus_shares: 'Bonus Shares %',
    right_shares: 'Right Shares %',
  };

  let withPercent = {
    gross_margin: 'Gross Margin',
    ebidta_margin: 'EBITDA Margin',
    operating_margin: 'Operating Margin',
    net_margin: 'Net Margin',
    revenue_growth: 'Revenue Growth',
    gross_profit_growth: 'Gross Profit growth',
    ebidta_profit_growth: 'EBITDA Growth',
    operating_profit_growth: 'Operating Profit Growth',
    profit_before_tax_growth: 'Pre-Tax Profit Growth',
    net_profit_growth: 'Net profit Growth',
    roe: 'ROE',
    roa: 'ROA',
  };
  if (valData.length && isLoading) {
    return (
      <div className="ratio-main">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={2}>Ratios</th>
              {valData
                ? valData[0].data.slice(-10).map((year, i) => {
                    return i !== 10 ? <th>{year.year}</th> : null;
                  })
                : null}
            </tr>
          </thead>
          <tbody>
            {valData.map((r) => {
              return (
                <tr>
                  <td colSpan={2}>
                    {r.label} <span className="table-unit-col">({r.unit})</span>
                  </td>
                  {r.data.slice(-10).map((d, i) => {
                    return i !== 10 ? (
                      <td>{valueCheckGlobal(d.value)}</td>
                    ) : null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div
        style={{ width: '100%', height: '100%', color: '#426fd7' }}
        className="main-loader d-flex flex-column justify-content-center align-items-center"
      >
        <img src={LoadingImage} style={{ height: '500px' }} />
      </div>
    );
  }
};

export default RatioTable;
