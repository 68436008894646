// ======================================
//                  INDEX
// ======================================

export const minusValueCheckGlobal = (value) => {
  let parsedNumber = null;

  // check if condition is parsable value

  if (isNaN(value) || value == null) {
    parsedNumber = '0.00';
  } else {
    parsedNumber = value;
    /*
     parsedNumber = (Math.round(parsedNumber * 100) / 100).toFixed(2);
  */
    if (parsedNumber < 0) {
      parsedNumber = parsedNumber * -1;
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      //   parsedNumber = Math.abs(parsedNumber);
      parsedNumber = '(' + parsedNumber + ')';
      // parsedNumber = (Math.round(parsedNumber * 100) / 100).toFixed(2);
    } else {
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  // Round numbers

  return parsedNumber;
};
/*
    1. value check and parse  
*/

export const valueCheckGlobal = (value, addpercentAge = false) => {
  let parsedNumber = null;

  // check if condition is parsable value

  if (isNaN(value) || value == null) {
    parsedNumber = '0';
  } else {
    parsedNumber = Number(value);
    if (parsedNumber < 0) {
      parsedNumber = addpercentAge
        ? `${(parsedNumber / parsedNumber) * -parsedNumber}%`
        : (parsedNumber / parsedNumber) * -parsedNumber;
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (parsedNumber >= 1000) {
        if (addpercentAge) {
          parsedNumber = '(' + Math.round(parsedNumber).toLocaleString() + '%)';
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          parsedNumber = '(' + Math.round(parsedNumber).toLocaleString() + ')';
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      } else {
        if (addpercentAge) {
          parsedNumber = '(' + parseFloat(parsedNumber).toFixed(1) + '%)';
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          parsedNumber = '(' + parseFloat(parsedNumber).toFixed(1) + ')';
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }
    } else if (parsedNumber >= 0 && parsedNumber < 1000) {
      parsedNumber = addpercentAge
        ? `${parseFloat(parsedNumber).toFixed(1)}%`
        : parseFloat(parsedNumber).toFixed(1);
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      parsedNumber = addpercentAge
        ? `${Math.round(parsedNumber).toLocaleString()}%`
        : Math.round(parsedNumber).toLocaleString();
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  // Round numbers

  return parsedNumber;
};

// Header Object

export const formatMoney = (n) => {
  return (Math.round(n * 100) / 100).toLocaleString();
};

// Market Cap Calculation

export const FindMarketCap = (stock_price, total_outstanding_share) => {
  // console.log(stock_price * total_outstanding_share);
  return stock_price * total_outstanding_share;
};

// Find Enterprise Value

export const FindEnterpriseValue = (market_cap, total_debt, cash) => {
  let ans = market_cap + total_debt - cash;
  return ans;
};

// SPECIAL CHARACTERS REMOVER

export const removeSpecialCharacters = (string) => {
  var newString = string.replace(/[^A-Z0-9]/gi, '_');
  return newString;
};



