import React, { useEffect, useState } from 'react';
import ValuationChart from './valuationChart';
import axios from 'axios';
import './valuation.css';
import { baseUrl } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';

const Valuation = (props) => {
  let compId = props.company.id;
  let objIds = [];
  let objIdTwo = [];
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);

  const [valData, setValData] = useState([]);

  /* useEffect(() => {
    setValData(props.allData);
    // console.log(props.allData, "all data in valuation graphs")
  }); */
  // console.log('valuation', compId);

  useEffect(() => {
    fetch(`${baseUrl}/valuation/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValData(data);
        // console.log('ab change howa hai ' + compId, data);
        setIsLoading(true);
      });
  }, [props.company.id]);

  let arrExist = Object.keys(valData);

  if (valData !== undefined) {
    objIds = [
      {
        id: 'v1',
        title: 'P/E Multiple',
        key: 'price_to_earning',
        color: '0B254A',
        description:
          'FFL is trading at a TTM P/E multiple of -1.9x, which is close to 1 standard deviation above the mean TTM P/E of -4.6x',
        data: valData.price_to_earning,
      },
      {
        id: 'v2',
        title: 'P/BV Multiple',
        key: 'price_to_book_value',
        color: '3969D5',
        description:
          'FFL is currently trading at a P/BV multiple of 0.9x, which is just below its mean 1.13x.',
        data: valData.price_to_book_value,
      },
      {
        id: 'v3',
        title: 'P/S Multiple',
        key: 'price_to_sales',
        color: 'B7CD5E',
        description:
          'FFL is trading at a TTM P/S multiple of 0.7x, which is close to 2 standard deviation below the mean TTM P/S of 1.99x.',
        data: valData.price_to_sales,
      },
      {
        id: 'v4',
        title: 'EV/EBITDA Multiple',
        key: 'ev_to_ebidta',
        color: '717375',
        description:
          'FFL is currently trading at a EV/EBITDA multiple of -8.0x, which is just below its mean -7.87x',
        data: valData.ev_to_ebidta,
      },
      {
        id: 'v5',
        title: 'EV/Sales Multiple',
        key: 'ev_to_sales',
        color: 'D2E5FF',
        description:
          'FFL is trading at a TTM EV/Sales multiple of 0.7x, which is close to 2 standard deviation below the mean TTM EV/Sales of 1.99x.',
        data: valData.ev_to_sales,
      },
    ];
  }

  // var value;

  // Object.keys(valData).forEach(function(key) {
  //     value = valData[key];
  //     console.log("individual Chart Data ============> ", value);
  // });

  // console.log("Valuation Chart Data ==========================>", valData);

  return (
    <>
      <div className="valuation-main">
        <div className="d-flex flex-wrap justify-content-between">
          {objIds.map(
            (objId, index) =>
              arrExist.includes(objId.key) ? (
                <div
                  key={index + 'valuation'}
                  className="col-xl-6 d-flex flex-wrap justify-content-between px-0 pl-xl-2"
                >
                  <div className="valuation-block col-12 p-0">
                    <div className="title-block block-head-bg-dark">
                      <h4>{objId.title}</h4>
                      {/* <p>{objId.title}</p> */}
                    </div>
                    <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
                      <ValuationChart eleId={objId} company={compId} />
                    </div>
                  </div>
                </div>
              ) : null
            // <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
            //     <h3>No Data Found</h3>
            // </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Valuation;
