import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import './cash.css';

const CashFlowTable = ({ company }) => {
  let companyId = new FormData();
  const [data, setData] = useState();

  // console.log("Response Data =========>", data);
  useEffect(() => {
    companyId.append('company_id', company.id);
    // console.log("company in cash flow =======>", company.id)
    axios({
      method: 'post',
      //url: 'https://askanalyst-testing.akseerresearch.com/api/cashflow',
      // data: companyId,
      url:
        'https://askanalyst-testing.akseerresearch.com/api/cashflowapi/' +
        company.id,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        setData(response.data);
        // console.log('Response Data =========>', response.data);
      })
      .catch(function (response) {
        //handle error
        // console.log(123, response);
      });
  }, [company]);

  if (data) {
    return (
      <>
        <div className="cashflow-main">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={2}>Cashflow Statement - PKR (mn)</th>
                {data[0].data.slice(-11).map((d) => (
                  <th>{d.year}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr className={d.label === 'Net Income' ? 'bold' : ''}>
                    <td colSpan={2}>{d.label}</td>
                    {d.data.map((_d) => (
                      <td>{valueCheckGlobal(_d.value)}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  } else {
    return <h5>loading . . .</h5>;
  }
};

export default CashFlowTable;
