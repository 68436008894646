import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Slider from 'react-slick';
import Chart from './Chart';
import SlickNextArrow from '../../../../common/slicknext';
import SlickPrevArrow from '../../../../common/slickprev';
import LoadingImage from '../../../../assets/media/loader.gif';
import { Redirect, useHistory } from 'react-router-dom';

/* import FinanceIncome from './financeIncome';
import FinanceBalance from './financialBalance';
import FinanceRatio from './financialRatio';
import ChartSlider from './ChartSlider';
*/
// import ChartSection from './ChartSection';

const SectorCharts = () => {
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [charts2, setCharts2] = useState([]);
  const accessToken = cookies.accessToken;

  let comp_id = 189;
  const settings = {
    dots: false,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /* "date": "2015-01-31",
                    "PVC": "801",
                    "Ethylene": "950",
                    "VCM": "698",
                    "Caustic": "297",
                    "PVC-VCM": "97.86",
                    "PVC-Ethylene": "335.23",
                    "PVC-Ethylene+Caustic": "537.48" */

  const objIds = [
    {
      title: 'PVC-VCM Margin',
      value: 'PVC-VCM',
      color: '0B254A',
      unit: 'USD/Ton',
    },
    {
      title: 'PVC-Ethylene Margin',
      value: 'PVC-Ethylene',
      color: '0B254A',
      unit: 'USD/Ton',
    },
    {
      title: 'PVC-Ethylene+Caustic Margin',
      value: 'PVC-Ethylene+Caustic',
      color: '0B254A',
      unit: 'USD/Ton',
    },
    {
      title: 'PVC Prices',
      value: 'PVC',
      color: '0B254A',
      unit: 'USD/Ton',
    },
    {
      title: 'Ethylene Prices',
      value: 'Ethylene',
      color: '0B254A',
      unit: 'USD/Ton',
    },
    {
      title: 'VCM Prices',
      value: 'VCM',
      color: '0B254A',
      unit: 'USD/Ton',
    },
    ,
    {
      title: 'Caustic Prices',
      value: 'Caustic',
      color: '0B254A',
      unit: 'USD/Ton',
    },
  ];
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${cookies?.accessToken}`;
  // console.log(comp_id);
  useEffect(() => {
    axios
      .post('https://askanalyst-testing.akseerresearch.com/api/pvccharts', {
        company_id: 189,
      })
      .then(function (response) {
        // console.log('RESPONSE ========>123', charts2);
        setCharts2(response.data);
        setIsLoading(true);
        console.log('RESPONSE ========>456', charts2);
      })
      .catch(function (error) {
        console.log('ERROR IN FINANCIAL CHART API RESPONSE====>', error);
      });
  }, []);
  if (!accessToken) {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  } else {
    if (isLoading) {
      return (
        <div className="financial-chart-block d-flex flex-wrap justify-content-between">
          {charts2.map((chart, index) => {
            {
              /* console.log('here111111', chart.graph.data); */
            }
            {
              /* var abc = chart.section.replace('Income Statement', 'Monthly');
            abc = chart.section.replace('Balance Sheet', 'Quartely');
            abc = chart.section.replace('Ratios', 'Annual'); */
            }
            return (
              <div className="finance-income col-12 p-0">
                <div className="title-block block-head-bg-dark">
                  <h4>Charts - {chart.section}</h4>
                </div>
                <div className="col-12 data-block p-0">
                  <Slider {...settings}>
                    {objIds.map((titles, index) => {
                      return (
                        <>
                          <Chart
                            section={chart.section}
                            data={chart.graph.data}
                            index={index}
                            title={titles.title}
                            value={titles.value}
                            unit={titles.unit}
                          />
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      );
    }
  }
};
export default SectorCharts;
