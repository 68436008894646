import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FinanceIncome from './financeIncome';
import FinanceBalance from './financialBalance';
import FinanceRatio from './financialRatio';
import ChartSlider from './ChartSlider';
import ChartSection from './ChartSection';

const FinancialCharts = (props) => {
  let comp_id = props.company.id;

  const [charts, setCharts] = useState([]);

  useEffect(() => {
    axios
      .post(
        'https://askanalyst-testing.akseerresearch.com/api/financialchart',
        {
          company_id: comp_id,
        }
      )
      .then(function (response) {
        setCharts(response.data);
      })
      .catch(function (error) {
        console.log('ERROR IN FINANCIAL CHART API RESPONSE====>', error);
      });
  }, [props.company.id]);
  return (
    <>
      <div className="financial-chart col-12 p-0">
        {/* <div className="title-block">
          <h4>Financial Charts</h4>
        </div> */}
        <div className="col-12 data-block p-0">
          {charts.map((section, index) => {
            return <ChartSection section={section} company={props.company} />;
          })}

          {/* <FinanceIncome/>
                    <FinanceBalance/>
                    <FinanceRatio/> */}
        </div>
      </div>
    </>
  );
};
export default FinancialCharts;
