import React from 'react';
import { icon } from '../../../api/baseUrl';

function RevenueDriver(props) {
  let revenue = props.revenue;
  if (revenue !== null && revenue !== undefined) {
    return (
      <div className="revenue-drivers-block">
        <div className="title-block block-head-bg-dark">
          <h4>{revenue.heading}</h4>
        </div>
        <div className="col-12 p-0 data-block flex-wrap justify-content-between">
          {revenue.data.map((value, key) => {
            return (
              <div class="row item">
                <div class="col-md-1 column ui-sortable align-middle icon">
                  <div dangerouslySetInnerHTML={{ __html: icon }} />
                </div>
                <div class="col-md-11 column ui-sortable align-middle text">
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: value.value }} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="revenue-drivers-block">
        <div className="title-block block-head-bg-dark">
          <h4>Revenue Drivers</h4>
        </div>
      </div>
    );
  }
}

export default RevenueDriver;
