import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import IdleTimer from '../../IdleTimerContainer';
import Header from '../../common/header/header';
import Wordsvg from '../../assets/media/files/Word.svg';
import Excelsvg from '../../assets/media/files/Excel.svg';
import Pdfsvg from '../../assets/media/files/Pdf.svg';
import Powerpointsvg from '../../assets/media/files/Powerpoint.svg';
import SessionExpire from '../../layout/login/sessionExpire';
import DownloadLink from 'react-download-link';
import LoadingImage from '../../assets/media/loader.gif';
// import './tabs.css';
import { Redirect, useHistory, Link } from 'react-router-dom';
import fileDownload from 'js-file-download';

// import '../../dashboard/tabs/tabs.css';
import { useCookies } from 'react-cookie';
// import Loader from '../../assets/media/_loader.gif';
import axios from 'axios';
// import './financial.css';
import SectorTabs from './tabs/SectorTabs';

import DataTable from 'react-data-table-component';
function Index(props) {
  const [sessionMessage, setSessionMessage] = useState('');
  const [sessionModal, setSessionModal] = useState(false);
  const [dark, setDark] = useState(false);
  const [financial, setFinancial] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = cookies.accessToken;
  // const [alert, setAlert] = useState(false);
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${cookies?.accessToken}`;
  /* Session expiry functionality start */

  /* if (alert === true) {
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  } */

  const sessionTimer = localStorage.getItem('sessionTime');

  function sessionTimeout() {
    setSessionModal(false);
    removeCookies('accessToken');
    removeCookies('user');
    removeCookies('userInformation');
    localStorage.removeItem('sessionTime');
    history.replace('/');
  }

  var count = sessionTimer * 1000;
  setTimeout(() => {
    setSessionMessage('Your session has timed out. Please login again.');
    handleSession();
  }, 3600000);

  const handleSession = () => {
    setSessionModal(true);
  };

  const closeModal = () => {
    setSessionModal(false);
    sessionTimeout();
  };

  if (!accessToken) {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }

  return (
    // const {setFinancial} = setFinancial;
    <>
      <IdleTimer
        handleSession={handleSession}
        setSessionMessage={setSessionMessage}
      />
      <Header dark={dark} setDark={setDark} />
      <div className={dark === true ? 'dashboard-main dark' : 'dashboard-main'}>
        <SectorTabs />
      </div>
      <SessionExpire
        sessionModal={sessionModal}
        closeModal={closeModal}
        sessionMessage={sessionMessage}
      />
    </>
  );
}

export default Index;
const styles = {
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
